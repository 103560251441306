// form operations

import { Err, Ok } from "../dsl"
import { FormBase } from "./FormBase"




export type Form$ = {
    submit: (event:any) => void  // <--  obviously, needs to be provided by the specific component
    change: (event:any) => void
}


// -- form - to replace
export const changeP = (_, $) => (evt) =>  $.run(function*($$) {
    evt.preventDefault()
    const {name, value} = evt.target
    const {values}:FormBase<any> = yield $$.Get
    yield $$.Set({values:{...values, [name]:value}})
})



export const addOptionsP = (_, $) => (field:string, newOptions:{id:string, rurl}[]) => $.run(function*($$) {
    const {options} = yield $$.Get
    yield $$.Set({options:{...options, [field]:newOptions}})
})


// -- validation effect
// () => Promise (Result a)  <-- where a is the type 
export const validateP = (_, $) => ()  => $.run(function*($$) {
    const pm:FormBase<any> = yield $$.Get    
    const errors = doValidate(pm)    // <-- this is reuseable if we inject the validator
    
    if (errors) {
        yield $$.Set({errors, hasError:true})
        return Err( {message:"didn't validate"})  // <-- could provide a summarty. But the key thing is that the errors are already in state
    } else {
        yield $$.Set({errors:{}, hasError:false})
        return  Ok(pm.values) 
    }

  })




/**
 * Validate based on a FormSchema
 * 
 *  custom ~ custom form validators, ie
 * 
 *  custom {
 *      //  required is automatic 
 *      verifypassword: (v, values) =>  ( (v == values.passord)  "does not match" : null)
 *      
 *  }
 *  
 */
const doValidate = (pm:FormBase<any>)  => {
    const {values, formSchema:schema, validators} = pm
    var errors
    for (var def of schema) {
        var {id, label} = def
        var err:any = null
        var value = values[id]
        if (validators && validators[id]) {
            err = null
            const validator = validators[id]
            err = validator(value, values);  
            if (err) break
        } else {
          if ((!value || value == "") && !def.optional) {
            err = `required: ${label}`
          }
          if (err) {
            errors = errors || {}
            errors[id] = err
          }
        }
    }
    return errors
}
