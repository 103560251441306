import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./configureStore";


import {theme} from "./ui/theme/theme"
import { ThemeProvider } from "@material-ui/core";

const store = configureStore()// , window.REDUX_STATE)


ReactDOM.render(

  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />  
        </Provider> 
     </ThemeProvider>

  </React.StrictMode>,

  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
