import { toFBUser } from '../auth/current-user-ps';
import { FBUser } from '../auth/User-dsl';
import { Err } from '../dsl';

const DEFAULT_AVATAR = "public/avatars/a1.jpg"


// -- Q: do we couple this to 
export const loginP = (ps,_) => (email, password) => {
    return new Promise((accept, reject) => {
        ps['<auth|']
        .signInWithEmailAndPassword(email, password)
        .then(signedInUser => {
            console.log(signedInUser);
            accept({ok:true})      // <-- note that the actual use will be recieved by a seperate listenng process
        })
        .catch(err => {
            accept({ok:false, err})
        })  
    })
}

export const signoutP = (ps, _) => () => {
    return ps['<auth|'].signOut()
}
    


//  -> Promise (Result FBUser)
export const registerUserP = (ps, $) => (email, password, username) => {
    
    return new Promise((accept, _) => {     // <-- maps errors to Result

    ps['<auth|'] 
        .createUserWithEmailAndPassword(email, password)
        .then(createdUser => {
            console.log(createdUser);
            createdUser.user!
            .updateProfile({
                displayName: username,
                // TODO -  alternate default avatar  
                photoURL:  DEFAULT_AVATAR
                
                //`http://gravatar.com/avatar/${md5(createdUser.user!.email)}?d=identicon`
            })
            .then(() => {
                const user:FBUser = toFBUser(createdUser)
                accept(user)     
            })
         .catch(err => accept(Err(err)))

    })
    .catch(err => accept(Err(err)) ) 
    })
}



