import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import firebase from "../../../firebase"
import { useCurrentPage, usePage } from "../../../model/ps/usePageLoader"
import { ReduxActions } from "../../../model/ReduxActions"
import {  PageState } from "../../../model/resource/PageResource"
import { Async, AsyncUtil, Dispatcher, dsl, State, suffixed } from "../../../ps/dsl"
import { CatDocsPM , createCatDocsPM } from "./CatDoc-pm"

export type ShowCatDocsPM = {
    docs?:CatDocsPM
    status:'loading'|'ready'
    rurl?:string
}

const pm0:ShowCatDocsPM = {
    status:'loading'
}

export const useCatDocs = () => {
    
    // -- page representation 
    const rurl = useCurrentPage()
    const page:PageState<any,any> = usePage(rurl)
    
    const [pm, setPm] = useState(pm0)
    
    const dispatch = useDispatch()

    const ps = useMemo(() => ({
        '<CatDoc[]|':  firebase.db.collection('CatDoc')
    }),[])

    const $ = useMemo(() => {
        var $:any = {}
        const effs = suffixed({
            startP, reloadP, loadCatDocsP,
            selectDocP
        }, {P:[ps,$]}) 

        $.run = dsl([
            State(pm, setPm),
            Async(effs), 
            Dispatcher(ReduxActions, dispatch), 
            AsyncUtil
        ]) 

        return {...$, ...effs}
    },[])

    // --  upon change to the page or 
    useEffect(() => {
        const p = $.start(page.data, page.resource.params, rurl)
        return () =>  p.cancel()
    }, [page.data, page.resource.params, rurl])

    return {pm, $}
}

const startP = (_, $) => (docs:any ,filter, rurl) => $.run(function*($$) {

    const pm = $$.Get   
    // -- 1. if we need to load 
    const needToLoadAllDocs = (docs == null)
    yield $$.Set({rurl, status:(needToLoadAllDocs ? "loading" : "not loading")  })

    if (needToLoadAllDocs) {
        // could set status to loading ...
        const {ok, v, err} = yield $$.loadCatDocs()

        if (ok) {
            const docPM = createCatDocsPM(v)
            yield $$.SET_DOC(rurl, docPM)  // <-- 
        } else {
            $$.Set({rurl, docs:undefined, status:"failed to load"})
        }
    }  else {
        yield $$.Set({docs:docs.doc, status:'ready', rurl})
    }


})

const reloadP = (ps,$) => () => $.run(function* ($$){
    const pm = yield $$.Get

    if (pm.rurl) {

        // yield $$.retrieveCatDocForView(rurl)
        // -- FIX_THIS - duplicates code
        const {ok, v, err} = yield $$.loadCatDocs()
        // -- or maybe this is the part that should be abstracted??
        if (ok) {
            const docPM = createCatDocsPM(v)
            yield $$.SET_DOC(pm.rurl, docPM)  
        } else {
            $$.Set({docs:undefined, status:"failed to load"})
        }
    }
})





const loadCatDocsP = (ps, $) => (user?:string) => $.run(function*($$) {
    const r = yield $$.result( (Ok, Err) => {
        ps['<CatDoc[]|'].get()
            .then(querySnapshot => {
                const out:any[] = []
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    var data = doc.data()
                    data.id = doc.id
                    out.push(data)
                });
                Ok(out)
            }).catch(e => {
                Err(e)
            })

    })
    return r  

})


export const selectDocP = (_, $) => id => $.run(function*($$) {
    yield $$.CAT_DOCS_VIEW(id)
})

