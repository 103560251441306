import { EditorState, SelectionState } from "draft-js"
import { UIState } from "../../../../model/ui/ui-pmodel"
import { BASE_DOC_ID } from "../CatDocsTest-ps"
import { DocCache } from "../DocCache-dsl"


export const changeP = (_, $) => (docId, tag, state:EditorState) => $.run(function*($$) {
    //console.log(`#${tag} ${value}`)

    const rurl = `CatDoc::${docId}:${tag}`
    const cache:DocCache = yield $$.DocCache
    const requireSave = cache.setContent(docId, tag, state)


    // -- this is inner most loop, invoked on each keystoke - invokes upon each change, so
    //      a) we optimize to only send these event if  necessary
    //      b) the doc renderer is protected (my memoization) against re-rendering on these changes
    //      
    //     
    const ui:UIState = yield $$.UIState       
    if (!ui.requireSave && requireSave) {  
        yield $$.SET_REQ_SAVE(true) // <-- only send this 
    }   

    if (docId == BASE_DOC_ID) {
        const sel:SelectionState = state.getSelection()
        if (sel &&  !sel.isCollapsed()) {
            if (!ui.canQuote || ui.canQuote.sel !== sel  ) {
                yield $$.SET_CAN_QUOTE({docId, tag, sel, $ })  // <-- FIX_THIS very, very hacky
            }
        }  else {
            if (ui.canQuote) {
                yield $$.SET_CAN_QUOTE(null)
            }
        }
    }

    


})