import { CatSchema, TagRef } from "../../../../model/cats/model/CatSchema"

export const loadSimpleSchemaP =  (ps, $) => schemaId => $.run(function*($$) {
    const r = yield $$.result( (Ok, Err) => {
        ps['<Cat[]|'].doc(schemaId)   // <-- not sure if this is a valid key
         .get().then( doc => {
            if  (!doc.exists) {
                Err("schema not exist")
            } else {
                const data = doc.data()
                const {content, title, raw, rurl} = data
                const schema:CatSchema<TagRef> = JSON.parse(content)  
                //const model:CatModel = CatModel(rurl, title || 'no title', schema, raw )  // <-- Q: what is title
                //const pm = docToCatPM(data, rurl,  createViewModel) 
                Ok(schema)
            }
         }).catch(Err)

    })
    return r
})
