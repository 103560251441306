import { catsReducer } from './cats/catsReducer';
import { uiReducer } from './ui/uiReducer';
import { Model, defaultState } from "./model";
import { PageState, setLoading, setPage, setReady, setErr, getPageStatus, setPageData } from "./resource/PageResource";
import { CatsPM } from './cats/model/CatsPM';
import { userReducer } from './userReducer';

const modelReducer = (state: Model, action: any): Model => {
  if (!state) {
    return defaultState();
  }

  var newState: Model = state;
  var page: PageState<any, any>
  var page1: PageState<any, any>


  switch (action.type) {

    case "LOAD_START": 
      ({page}= action);
      page1 = { ...page,status: setLoading(page) };
      newState = setPage(state, page1);
      break;

    case "LOAD_COMPLETE":
      var data:any;
      ({page, data} = action);   
      newState = setPageData(state, page.resource.rurl, data)
      break;

    case "LOAD_ERROR":
      var e;
      ({ page, e } = action);
      page1 = { ...page,data:undefined, status:setErr(page,e) }
      newState = setPage(state, page1);
      break;

    case "Notification":
      // Notification
      console.log("Got notification: " +  action.msg + "\n---details:---\n" + action.details)
      break

    case "NavTo":
      const url = action.url
      console.log("TODO - navigate to " + action.url)
      break;
   
    
      
    case "SET_DOC":
        let {rurl, doc} = action
        var page  = getPageStatus(state, rurl)
        var {data} = page;
        data = {...data, doc}
        page = {...page, data}
        newState = setPage(state, page)
        break
        
    case "SET_CAT":
      newState = catReducer(state, action)
      break
    
    case "SET_SAVE":
      newState = {...state, requireSave:action.v} 
      break
    
    
    case "SET_MENU":
    case "SET_DRAWER":
    case "SET_REQ_SAVE":
    case "SET_SAVE_IN_PROGRESS":
    case "SET_SHOW_BASE_DOC":
    case "SET_CAN_QUOTE":
      return { ...state, uiState: uiReducer(state.uiState, action) }

    case "SET_USER":
    case "CLEAR_USER":
        return {...state, user:userReducer(state.user, action)}
      default:
      newState = catsReducer(state, action);
  }

  return newState;
};


/// -- Categories reducer


const catReducer  = (state:Model, action):Model => { 
  var newState:Model = state
  switch (action.type) {
    case "SET_CAT":
      const {pm}:{pm:CatsPM<any>} = action
      var page  = getPageStatus(state, pm.model.rurl) 
      if (page.data != pm) {
        page = {...page, data:pm} 
        newState = setPage(state, page)
      }
      newState = {...newState, cats:pm}
  }
  return newState
}



export default modelReducer
