import * as React from "react";
import useFormValidation from "./useFormValidation";
import firebase from "../../firebase";
import { useDispatch } from "react-redux";


import Button from '@material-ui/core/Button';
import { registerValidator } from "./validateRegister";
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";

import { BaseForm } from "./BaseForm";
import Link from "redux-first-router-link";
import { useRegisterPs } from "../../ps/user/register-ps";





export const Register = () => {

  const {pm, $} = useRegisterPs()
  return BaseForm(pm, $)
}

