import * as React from "react"
import {Fragment} from "react"
import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import HomeIcon from '@material-ui/icons/Home';
import { useStyles } from "../../ui/theme/styles";
import { NavActions, navTo } from "../pageReducer";


import { useDispatch } from "react-redux";
import { useCurrentCats, useCurrentPage, usePage } from "../ps/usePageLoader";
import { CatsPM } from "./model/CatsPM";
import NoteIcon from '@material-ui/icons/Note';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AssignmentIcon from '@material-ui/icons/Assignment';


const EMPTY =[]

export const CatList = ({ open }) => {
  const styles = useStyles()
  const dispatch = useDispatch() 
  const current = useCurrentPage()
  const pm:CatsPM<any> = useCurrentCats()  
  const page = usePage(current)
  //const cats:CatItem[] = pm ? pm.cats : EMPTY  // <-- current categories
  const cats = null

  const {type, params} = page.resource
  const docId = params ? params.id : null

  const out = React.useMemo(
    () => render(styles, dispatch,  current, cats, open, docId), 
    [docId, current, open]       // <-- memoize on 
  )
  
  return out
}

const CATEGORIES = [
 // {rurl:'/cats/lea/thesis/thesis-categories', text:"Lea's categories"},
 // {rurl:'/cats/lea/thesis/narrative', text:"Ian's categories"},
  {rurl:'/cat-docs-view/cnotes', id:"cnotes", text0: "notes", text:"Conclusion Notes"},
  {rurl:'/cat-docs-view/read1', id: "read1", text0: "read1", text:"Read-throught 1"},

  {rurl:'/cat-docs-view/con1', id: "con1", text0: "compare", text:"Compare conclusion, text"},
  {rurl:'/cat-docs-view/sharp1', id:"sharp1", text0: "sharp1", text:"Sharpening 1"},
  {rurl:'/cat-docs-view/sharp2', id:"sharp2", text0: "sharp2", text:"Sharpening 2"},
  {rurl:'/cat-docs-view/read2', id: "read2", text0: "read2", text:"Read throught 2"},

  {rurl:'/cat-docs-view/titles', id:"titles", text0: "titles", text:"Notes on Titles"},
  {rurl:'/cat-docs-view/ref', id:"ref", text0: "ref", text:"Referenceing notes"}


]

const THEMES = [
  // {rurl:'/cats/lea/thesis/thesis-categories', text:"Lea's categories"},
  // {rurl:'/cats/lea/thesis/narrative', text:"Ian's categories"},
   {rurl:'/cat-docs-view/theme1', id:"theme1", text0: "imper", text:"'imperative'"},
   {rurl:'/cat-docs-view/theme2', id: "theme2", text0: "5th", text:"5th force"},
   {rurl:'/cat-docs-view/theme3', id: "theme3", text0: "power", text:"Descriptions of power dynmamics"}



 ]

const DOCS = [
  

  {rurl:'/cat-docs-view/bibliography', id:"bibliography", text0: "bib", text:"Bibliography"},
  {rurl:'/cat-docs-view/bib', id:"refs", text0: "ref", text:"Referencing notes"},
  {rurl:'/cat-docs-view/thesis-v10', id:"thesis-v10", text0: "v10", text:"Thesis v10"},
  {rurl:'/cat-docs-view/thesis-v8', id:"thesis-v8", text0: "v8", text:"Thesis v8"},
  {rurl:'/cat-docs-view/thesis-vs', id:"thesis-vs", text0: "v7", text:"Thesis v7"}

]


const render = (styles, dispatch, current, cats, open,docId) => {
  var refs  = []
  var enableCats = false

    return (
    
    <Fragment>
 <List>
    {DOCS.map(({rurl, text, text0, id}, i) => (
           <ListItem button key={i}   onClick = {() => dispatch(navTo(rurl))}>
              {open && <ListItemIcon>  <AssignmentIcon/>  </ListItemIcon>}
              <ListItemText className={ id == docId ? styles.tagItem : undefined } primary={open ? text : text0}/>
            </ListItem>))}
      
  </List>
    <Divider/>
  
    <List>
    
        {enableCats  && <ListItem button key={"type"} onClick = {() => dispatch(NavActions.HOME())}>
              <ListItemIcon> <HomeIcon/> </ListItemIcon>
             {enableCats  && <ListItemText primary={" -- notes -- "} />}
         </ListItem>}

       
        {CATEGORIES.map(({rurl, text, text0, id}, i) => (
           <ListItem button key={i}   onClick = {() => dispatch(navTo(rurl))}>
              {open && <ListItemIcon> <NoteIcon/>  </ListItemIcon>}
              <ListItemText className={ id == docId ? styles.tagItem : undefined } primary={open ? text : text0}/>
            </ListItem>))}
</List>

    <Divider/>

  
<List>        
    {THEMES.map(({rurl, text, text0, id}, i) => (
           <ListItem button key={i}   onClick = {() => dispatch(navTo(rurl))}>
              {open && <ListItemIcon> <SpeakerNotesIcon/>  </ListItemIcon>}
              <ListItemText className={ id == docId ? styles.tagItem : undefined } primary={open ? text : text0}/>
            </ListItem>))}

           
     </List>
      <Divider/>
  
   

 </Fragment>)
}

/*
    <List>
          {refs.map(({txt}, index) => (
            <ListItem button key={index}>
              <ListItemIcon> <MenuBookIcon/> </ListItemIcon>
              <ListItemText className={index ==1 ?  styles.tagItem : undefined } primary={txt}
              />
            </ListItem>
          ))}
    </List>

    */


    /*
    {enableCats  &&
      (<List>  
      {cats.map(({ label, smallLabel , path, rurl}, i ) => (
            <ListItem button key={i} onClick = {() => dispatch(navTo(rurl))}>
              <ListItemText className={rurl == current ?  styles.tagItem : undefined }
              primary={open ? label : " " + smallLabel}  />
            </ListItem>
          ))}
        </List>)}
    */