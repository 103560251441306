import { setPage, addPageLinks, PageState, pageTorurl, createPageResource, resolvePageResource } from "./resource/PageResource";
import { Model } from "./model";
import { pathToAction, actionToPath } from "redux-first-router";
import queryString from 'query-string'
import assert from "./util/assert";

export const routesMap = {
  HOME: "/",
  USER: "/user/:userid",
  LOGIN: "/login",
  REGISTER:"/register",
  FORGOT: "/forgot",


  // -- project api 
  PROJECT: "/project/:user/:pname",
  BIB: "/bibliography/:user/:pname",


  REF: "/project/:user/:pname/:ref", 
  
  // -- documents
  DOCS:"/docs/:user/:project", 
  DOC: "/doc/:user/:id",   
  TESTDOC:"/testdoc/:user/:id",
  CATS: "/cats/:user/:project/:id",
  CAT_DOC: "/cats/:user/:project/:id/:tag",



  CAT_DOCS:"/cat-docs/:id",
  CAT_DOCS_VIEW:"/cat-docs-view/:id",  // <-- eventually replace with CAT_DOC_VIEW?? or some

  // -- document api 
  CREATE: "/create/:project",
  CREATE_CAT_DOC: "/create-cat-doc",

  LINK: "/link/:linkId",
  CATLIST: "catlist/:id",
  Search:"/search:cmd?"
  //[NOT_FOUND]: '404'
};
/*


              <Route path="/create" component={CreateLink} />
              <Route path="/login" component={Login} />
              <Route path="/forgot" component={ForgotPassword} />
              <Route path="/search" component={SearchLinks} />
              <Route path="/top" component={LinkList} />
              <Route path="/new/:page" component={LinkList} />
              <Route path="/link/:linkId" component={LinkDetail} />
*/

export const uriToAction = (ruri:string) => {
  return pathToAction(ruri, routesMap, queryString)
}
export const actionToURI = (action:any) => {
  return actionToPath(action, routesMap, queryString)
}

export const pageReducer = (state: Model, action: any = {}) => {
  if (routesMap.hasOwnProperty(action.type)) {
    let { type, payload, meta } = action;
    let { query, pathname: rurl } = meta.location.current;

    console.log("--- nav to : " + action.type);

    const defaultToReady = (type != "CATS")
    var page: PageState<any, any> = createPageResource(state, type, rurl, payload, query, defaultToReady);


 
    if (type == "DOC" || type == "TESTDOC" || type == "CAT_DOC") {
      
      // -- hypermeida
      var project:string = "/project/lea/essay"
      state = resolvePageResource(state,  project) // makes sure this is resolved

      page = addPageLinks(page, {project, projects:"/"})
      
      
      // -- filter. Note that for documents etc, query to filter is handled in resolve resouce page


      page.filter = {showView: (query && query.showView) ? true : false }

      if (query) {
        
        if (query.view) {
          var view = uriToAction(query.view)
          if (view) {
            if (view.type != "REF" && view.type != "PROJECT" && view.type != "HOME") {  // <-- only allow references to Project pages 
              assert(false, "")  // probably want project
            } else {
              const viewRurl = actionToURI({type:view.type, payload:view.payload } ) // <-- reconstruct w/o query
              const viewQuery = view.meta ? (view.meta as any).query : {};
              var viewPage:PageState<any,any> = createPageResource(state, view.type, viewRurl, view.payload, viewQuery)
              state = setPage(state, viewPage); 
              
              // -- a document might resonably be associated with a project 
              //   For the moment hardcoding this
              page = addPageLinks(page, 
                    {
                        view: pageTorurl(viewPage),
                    })
              // FIX_THIS bug - prevents hide view from 
              //page.filter.showView = true
              // -- Hardcoding reference to the underlying project page
            

            
            
            }

          


          }
        }
      }
    } 

    
    state = setPage(state, page);

    return { ...state, page };
  }

  return state;
};

  






export const navTo = rurl => {
  var action = uriToAction(rurl)
  return action
}

//  CAT_DOC: "/cats/:user/:project/:id/:tag",
// CAT_DOCS:"/cat-docs/:id",


export const NavActions = {
    //NavTo: navTo,  // <-- NavTo doesn't, in general, work with $.run yet 
    LOGIN: () =>  ({type:"LOGIN"}),
    HOME: () => ({type:"HOME"}),
    CAT_DOCS:(id:string) => navTo(`/cat-docs/${id}`),
    CAT_DOCS_VIEW: id => navTo(`/cat-docs-view/${id}`)
  }

