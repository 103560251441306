import * as React from 'react'

import { useState } from 'react'

import { MUIRichTextEditor } from '../../../../muiEditor/MUIRichTextEditor'
import { EditorState } from 'draft-js'
import { CatsPM } from '../../../../model/cats/model/CatsPM'
import { useCatDocEditorPs } from './CatDocEditor-ps'
import { PageState } from '../../../../model/resource/PageResource'



export type CatDocEditorPM<vm> = {
  docPage:PageState<any,any>
  
  schemaPage:PageState<CatsPM<vm>, any>  
  doc?:any,
  schema?:CatsPM<vm>  // <-- we don't care about the view state of schemaPM
  
  requiresCreate:boolean


  status:'not started'|'loading schema'|'loading doc'|'err'|'ready'
  errors:{message:string}[]
}


export const CatDocEditor  = () => {
   const {pm, tag, $}:{pm:CatDocEditorPM<EditorState>, tag:string, $:any} = useCatDocEditorPs()

  const {docPage, doc} = pm
  
  var err = null;
  //var state1:EditorState = null;

  
  if (!pm.schema) {
    return <h2>loading schema ...</h2>
  }  else if (!pm.doc) {
    return renderInvalid(pm.docPage,"missing tag data")
  }
  

  var tagData = pm.doc.children[tag] 
  if (!tagData) {
    return renderInvalid(docPage,"undefined tag #" + tag)
  } 
  
  const raw = tagData.doc

  



    //dispatch(CatSave())

    /*
    var doc = JSON.stringify(convertToRaw(state.getCurrentContent()))
    var docRef = firebase.db.collection("cats").collection("children").doc(tag)
    
    docRef.update({doc}).then(() => {
      const newPm = setDoc(pm, tag, doc)
      
      dispatch(SetCat(pm))
    }).catch(e => {
      console.log("error saving")
    })
   
*/
  

  

  return (
    <div> 
        <h1>Edit category notes: #{tag}</h1>
        <MUIRichTextEditor  defaultValue={raw} onChange={$.onChange} onSave={$.onSave} label={"some text"}/>
    </div>  
  )
}

const renderInvalid = (page, txt?) => (<div>Invalid Page: {page.resource.rurl} {txt||""}</div>) 




/*



#Ch1 I. Chapter One
#dsj Definition of Social Justice
#eo evolution and origin
#bc Bridge concepts
#eth Ethics / morals and do no harm
#val Values
#pe Professional identity
#ee Empirical evidence

#Ch2  II. mainstream approaches (critiques and positives)
#cbt CBT
#ps Psychoanalysis
#hum Humanism
#mc Multiculturalism
#fem Feminism
#lp Liberation Psychology

#Ch3 III. Frameworks
#msjcc MSJCC
#mpm MPM
#gm Goodman
#con9 Constantine’s 9 competencies
#sps Shela's Power Sensitisation
#oth Other frameworks

#Ch4 IV. Clinical Practice
#sk self knowledge
#sp sharing power/empowerment/ in the therapeutic relationship
#gv giving voice (narrative therapy etc)
#cr conciousness raising
#sb strenghts building
#tc tools for change

#Ch5 V.  Implications
#ri research implications
#ti training implications

*/



const setDoc = (pm:CatsPM<any>, tag:string, doc:string) => {
  const {children} = pm
  const child  = children[tag]
  var newChild = {...child, doc}
  var newChildren = {...children}
  newChildren[tag] = tag
  const newPM = {...pm, children:newChildren}
  return newPM
}