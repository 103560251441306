import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import CodeRounded from '@material-ui/icons/CodeRounded';
import { Ipsem } from './Ipsem';
import { useDispatch } from 'react-redux';
import { useCurrentPage } from '../../model/ps/usePageLoader';
import { useUI } from '../../model/ui/useUI';
import { ReduxActions } from '../../model/ReduxActions';
import { Bibliography } from '../../components/Project/Bibliography/Bibliography';
import { AppBar } from '@material-ui/core';
import { AntTab, AntTabs } from '../../components/Project/Bibliography/TabHelpers';

const useStyles = makeStyles(({ spacing, breakpoints, mixins }) => ({
  toolbarMargin: mixins.toolbar,
  sourceTrigger: {
    position: 'fixed',
    zIndex: 1000,
    bottom: spacing(2),
    right: spacing(2),
    [breakpoints.up('sm')]: {
      bottom: spacing(4),
      right: spacing(4),
    },
    [breakpoints.up('md')]: {
      bottom: spacing(5),
      right: spacing(5),
    },
  },
}));

export const BubbleDrawer = () => {
  const dispatch = useDispatch()
  const styles = useStyles();
  const page = useCurrentPage()
  const uiState = useUI()
  const {drawerOpen:open} = uiState

  const setOpen = open => dispatch(ReduxActions.SET_DRAWER(open)) 

    //  old witdh{ xs: '100vw', sm: 700, md: 500 }

    const width = `${uiState.menuOpen ? '40' : '48'}vw`
  

  return (
    <>
      <Drawer variant={'persistent'} anchor={'right'} open={open} onClose={() => setOpen(false)}>
      <div className={styles.toolbarMargin} />
      <Box width={width}>
          <Bibliography/>
      </Box>

      <Fab color={'primary'} className={styles.sourceTrigger}>
        <CodeRounded onClick={() => setOpen(false)} />
      </Fab>
      </Drawer>
      
      <Fab color={'primary'} className={styles.sourceTrigger}>
        <CodeRounded onClick={() => setOpen(true)} />
      </Fab>
    </>
  );
};

/*
SourceFileDrawer.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  source: PropTypes.string.isRequired,
};
*/
