import React from "react";
import { useDispatch } from "react-redux";
import { navTo } from "../../model/pageReducer";



 function useFormValidation(initialState, validate, authenticate) {

  const dispatch = useDispatch()
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});

  const [isSubmitting, setSubmitting] = React.useState(false);


  
  React.useEffect(() => {
    if (isSubmitting) {
      const noErrors =  !errors
      if (noErrors) {
        const doAuth = async () => {
          var vs = toVs(values)
          var response = await authenticate(vs);
          if (response == true)
          setSubmitting(false);
          console.log("authenticated", { values, vs, response });
          dispatch(navTo("/login"))
        }
        doAuth()
      } else {
        setSubmitting(false);
      }
    }
  }, [errors, dispatch]);

  /**
   * onChange when a material-ui   <TextField onChange /> 
   */
  const onChange = ({ target: { id, value } }) => {
    //    event.persist();
    const index = values.findIndex(vs => vs.id === id);
    var newValues = [...values];
    newValues[index] = { ...newValues[index], value };
    setValues(newValues);
  };




  


  function onSubmit(event) {
    event.preventDefault();
    var errors = validate(values);    
    setErrors(errors)
    if (!errors) {
      setSubmitting(true);
    }
  }



  function onBlur(event) {
    event.preventDefault();
    const errors = validate(values);
    setErrors(errors);
  }


  return {
    onChange,
    onSubmit,
    onBlur,
    values,
    errors: (errors as any),
    isSubmitting
  };
}


const toVs = values => {
  var vs = {}
  values.forEach(({value, id}) => (vs[id] = value))
  return vs
}


export default useFormValidation;
