import { useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import firebase from "../../../firebase"
import {  useParams } from "../../../model/ps/usePageLoader"
import { ReduxActions } from "../../../model/ReduxActions"
import { Async, AsyncUtil, Dispatcher, dsl, Reducer, Selectors, StateReducer, StateSelect, StateTerms, suffixed } from "../../../ps/dsl"
import { CatDocContentPM,  } from "./CatDocContent-model"
import { DocSelect, DocSelectReducer, DocSelectTerms, selFromString } from "./DocSelect-dsl"
import { getCatDocP} from "./eff/getCatDocP"
import { loadSimpleSchemaP} from "./eff/loadSimpleSchemaP"
import { loadCatDocContentP}  from "./eff/loadCatDocContentP"
import { useUI } from "../../../model/ui/useUI"
import { CacheDSL, DocCache } from "./DocCache-dsl"
import { useEditorStyles } from "./CatDocTest-styles"
import { UIState } from "../../../model/ui/ui-pmodel"
import { loadAndCacheCatDocP } from "./eff/loadAndCacheCatDocP"
import { saveP } from "./eff/saveP"
import { keyP } from "./eff/keyP"
import { doQuoteP, quoteP} from "./eff/doc/quoteP"
import { changeP } from "./eff/changeP"
import { useStart } from "../../../ps/dsl-react"
import { DocSelectPM } from "./DocSelectPM"

// -- temporary hard coding. Should be annotated
export const BASE_DOC_ID = "thesis-v10"  // <-- TODO - remove hardcoding. Also ... schema might as well come from base doc.

const __VIEW_STATE_CACHE:any = {}   // <-- pending proper uri integration
                                        // docId => {isOpen, folderOpen}


const pm0:DocSelectPM = {
    isOpen: {}, // <-- 
    status:'loading',
    //baseContent:{}
    folderOpen:{}
}



export const useCatDocsTestPs = ():{pm:DocSelectPM, classes:any, $:any}=> {
    //testSelectURLSerialize()

    const {id} = useParams() 
    const [pm, setPm] = useState(pm0) 
    const dispatch = useDispatch()
    const pmRef:any = useRef(null)
    const classes = useEditorStyles()
    //const app:Model = useSelector((s:AppState) => s.app)

    pmRef.current =  useUI()

 
    const $ = useMemo(() => {
        const ps = {
            '<CatDoc[]|': firebase.db.collection('CatDoc'),
            '<Cat[]|': firebase.db.collection('cats')
        }

        var $:any  = {}
        const effs = suffixed({
            // -- effects used by this component 
            startP,  //  $.start    <-- loads document 
            
            // -- doc view pm
            setOpenP,    // $.setOpen(v)       v = tag | "_" | "*" | "**"
            toggleOpenP,   // $.toggleOpen(tag) 
            toggleShowBaseP,   // <-- TODO - reuse
            setViewStateP,

            // --
            changeP,    // <-- take changes to documents, cache, update save requirements, 
            doQuoteP,
            quoteP,

            // -- firebase implementations
            getCatDocP,
            loadSimpleSchemaP,
            loadCatDocContentP,
            loadAndCacheCatDocP,
            // -- the change api
            // changeDataP :: docId -> tagId -> vm
            
            keyP,  // <-- key pressed
            saveP  // <-- save pressed 

        }, {P:[ps,$]})
        
        $.run = dsl([
            // -- composition of generic state + Open dsl

            Reducer({...StateTerms, ...DocSelectTerms},
                    {...StateReducer,  ...DocSelectReducer}, StateSelect, pm, setPm ),
            Async(effs), 
            CacheDSL(),
            Dispatcher(ReduxActions, dispatch, Selectors({UIState: pm => pm}, pmRef)),

            AsyncUtil
        ])
        return {...$, ...effs}
    }, [])

    useStart($, id)

    return {pm, classes, $}
}



// -- start load the doc, the schema & the doc content
export const startP = (ps, $) => id => $.run(function*($$) {
    if (!id) {
        $$.Set({status:'loading', docId:id})
        return
    }


    // -- 1. retrieve that basic doc
    const cache:DocCache  = yield $$.DocCache

    yield $$.Set({...pm0, cache})  
    
    var docPm:CatDocContentPM<any> = cache.getCachedDoc(id)
    var cached = (docPm != null)
    if (!cached) {
        
        const {ok, v, err } = yield $$.loadAndCacheCatDoc(id)

        if (ok)  {
            docPm = v
        } else {
            console.log(err)
            return // <--  are handled elsewhere
        }
    }


    // -- ensure reference doc is ready 
    const baseDoc = cache.getCachedDoc(BASE_DOC_ID)  
    const baseDocId = docPm.doc.id == BASE_DOC_ID ? null : BASE_DOC_ID
    
    
    // TODO 
    //  - validate that schema are the same 
    //  - cache schema
    // 
    if (baseDocId && !baseDoc) { 
        const uiState:UIState = yield $$.UIState
        if (!uiState.showBaseDoc) {
            yield $$.SET_SHOW_BASE_DOC(true)  // <-- DODO - cache this 
        }
        const {ok }  = yield $$.loadAndCacheCatDoc(baseDocId)
        if (!ok) {
           return 
        }
    } 
    


    

    yield $$.Set({docPm, status:'ready', baseDocId})      // <-- content is retrieved from the cache
    const viewCache = __VIEW_STATE_CACHE[docPm.doc.id]
    if (viewCache) {
        const {isOpen, folderOpen} = viewCache
        yield $$.setViewState(isOpen, folderOpen)
    } else {
        yield $$.setOpen("**")
    }

})






// --  manage doc open/close hierarchy 
const setOpenP = (_, $) => (v, tag?, isFolder?, e?) =>  $.run(function*($$) {

    const pm:DocSelectPM = yield $$.Get
    if (pm.status != "ready") {
        return
    }

    const {tags, doc} = pm.docPm!
    
    if (v == "**") {
        const cache:DocCache = yield $$.DocCache
        const isOpen = cache.nonEmpty(doc.id, tags) 
        var openFolders = {}
        const {tagChildren} = pm.docPm!
        Object.keys(tagChildren).forEach(k => {
            var children = tagChildren[k]
            openFolders[k] = children.reduce((v, k) => (v || isOpen[k]), false ) 
        } )


        yield $$.setViewState(isOpen, openFolders)
        
        return
    }
    // shift + close all -> close all except the selected 
    if (v == ""  && tag && e && e.shiftKey && !isFolder) {   // <-- TODO handle case of folder
        const parent:string = pm.docPm!.tagParents[tag]!
        yield $$.setViewState({[tag]:true, [parent]:true}, {[parent]:true})
        return
    }


    const isOpen =  selFromString(v, tags)
    const folderOpen = (v == "*" ? openAllFolders(pm):  pm.folderOpen)

    yield $$.setViewState(isOpen, folderOpen)

})

const setViewStateP = (_, $) => (isOpen, folderOpen) =>  $.run(function*($$) {
    const pm:DocSelectPM = yield $$.Get
 
    
    if (pm.docPm) {
        const docId = pm.docPm.doc.id
        __VIEW_STATE_CACHE[docId] = {isOpen, folderOpen}  // <-- TODO - proper URI implemnetioat
        yield $$.Set({isOpen, folderOpen})
    }
})

const toggleOpenP = (_, $) => (tag, isFolder) => $.run(function*($$) {
    
    const pm:DocSelectPM = yield $$.Get 
    var {isOpen, folderOpen} = pm
    
    isOpen = {...isOpen, [tag]:(!isOpen[tag])}
    if (isFolder) {
        folderOpen = {...folderOpen, [tag]:(!folderOpen[tag])}
    }
    yield $$.setViewState(isOpen, folderOpen)
    
  

})

const openAllFolders = (pm:DocSelectPM):any => {
    const out:any = {}
    Object.keys(pm.docPm!.tagChildren).forEach(k => {
        out[k] = true
    })
    return out
}








const toggleShowBaseP = (_, $) => () => $.run(function*($$) {
    yield $$.SET_SHOW_BASE_DOC()
})


