import { FormOptions } from "../../../../ps/form/FormBase"

export const getSchemaP = (ps, $) => () => $.run(function*($$) {
    
    
    const result = yield $$.result((Ok, Err) => {
        
        ps['<cats[]|'].get() // <-- no benefit this this
            .then(querySnapshot => {
                console.log('x')
                var schemas:FormOptions[] = []

                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    const data = doc.data()
                    schemas.push({rurl:data.rurl, id:doc.id})
                })
                Ok(schemas)
            }).catch(Err)
        })
    
    return result
})