
import firebase from "../../../../firebase"

export const createCatDocP = (ps, $) => (user:string, schemaId:string, title:string, id:string) => $.run(function*($$) {
    
    const data:any = {
        user, 
        schemaId, 
        title,
        created:firebase.TIMESTAMP() 
    }
    
    const r = yield $$.result( (Ok, Err) => {   
        const CatDocRef = ps['<CatDoc[]|']

        // -- different proceses for createing /w and w/o a doc id
        if (id)  {
            // -- create specific doc 
            CatDocRef.doc(id).get().then(doc => {
                    const exists = doc.exists 
                if (exists) {
                    Err({message:`doc already exists ${id}`})
                } else {
                    CatDocRef.doc(id).set(data)
                        .then(doc => {
                           Ok(id)
                        }).catch(Err)
                }
            }).catch(Err)
        } else { 
            CatDocRef.add(data).then(doc => {
                Ok(doc.id)
            })
        }

    })
    

    return r  
}) 