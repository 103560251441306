import {  useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "../../../model/ps/usePageLoader"
import { ReduxActions } from "../../../model/ReduxActions"
import { Async, AsyncUtil, Dispatcher, dsl, Selectors, State, suffixed } from "../../../ps/dsl"
import { useStart } from "../../../ps/dsl-react"
import { CacheDSL , DocCache } from "../../Categories/CatDocsContent/DocCache-dsl"


// -- TODO - abstract 
import { loadCatDocContentP } from "../../Categories/CatDocsContent/eff/loadCatDocContentP"
import { loadAndCacheCatDocP } from "../../Categories/CatDocsContent/eff/loadAndCacheCatDocP"
import { loadSimpleSchemaP } from "../../Categories/CatDocsContent/eff/loadSimpleSchemaP"
import { getCatDocP }         from  "../../Categories/CatDocsContent/eff/getCatDocP"

import { changeP }         from  "../../Categories/CatDocsContent/eff/changeP"
import { keyP }            from  "../../Categories/CatDocsContent/eff/keyP"
import { saveP }            from  "../../Categories/CatDocsContent/eff/saveP"



import { useUI } from "../../../model/ui/useUI"
import { CatDocContentPM } from "../../Categories/CatDocsContent/CatDocContent-model"

import firebase from "../../../firebase"
import { useEditorStyles } from "../../Categories/CatDocsContent/CatDocTest-styles"
import { BibModel, toBibModel } from "./model/ParseGenPM"
import { Hits, updateSearchResults } from "./model/searchRefs"

 export const MODES = {
        EDIT:0,
        SEARCH:1,
        PUB:2
 }

export type BibViewPM = {
    
    mode:number //  EDIT | SEARCH | PUB
    
    // -- search state
    searchValue:string
    searchInProgress:boolean
    searchResults?:Hits

    // -- doc state 
    docId?:string     // <-- TODO- derive from selected project
    docPm?:CatDocContentPM<any>  // <-- last saved/loaded version
    cache?:DocCache   // <-- may contain a more recent version of the doc that the docP,

    bibModel?:BibModel


    // -- resource loading status
    error?:string
    status:"unknown"|"loading"|"load-err"|"ready"

}

const pm0:BibViewPM = {  
    mode:MODES.EDIT,
    searchValue:'',
    searchInProgress:false,
    status:"unknown"
} 

export const useBibliographyPs = ():{pm:BibViewPM, $:any} => {
    const [pm, setPM] = useState(pm0)
    const params = useParams()
    const dispatch = useDispatch()
    const pmRef:any = useRef(null)
    pmRef.current =  useUI()

    const id = "bibliography"  // <-- hardcoded. Will concieveably come /w project


    const $:any = useMemo(() => {
        var $:any = {}
        const ps = {
            '<CatDoc[]|': firebase.db.collection('CatDoc'),
            '<Cat[]|': firebase.db.collection('cats')
        }
        const eff = suffixed({ 
            
            // -- TODO - massive duplication of code.  Has multiple dependenceis
            loadCatDocContentP,  // <-- this should be injected from a larger process
            loadAndCacheCatDocP,
            getCatDocP,
            loadSimpleSchemaP,

            // -- 
            keyP,
            changeP, 
            saveP,
            // quoteP  <-- TODO quote api needs thought.  Argueable just disable it here
            //              or make it a Fab
            
            // -- 
            startP,  
            forceReloadP,
            
            setModeP,  // <-- edit | search | publish (invokes generation of model)

            setSearchP,      // <-- search 
            searchRequestP
        }, {P:[ps, $]})

        $.run = dsl([
            State(pm0, setPM),
            Async(eff),
            CacheDSL(), 
            Dispatcher(ReduxActions, dispatch, Selectors({UIState: pm => pm}, pmRef)),
            AsyncUtil
        ])
        return {...$, ...eff}
    }, [])

    
    useStart($, id)


    return {pm, $}
    
}


const forceReloadP = (_, $) => id =>  $.start(id)   // <-- TODO stop existing ps


const startP = (_, $) => (docId) => $.run(function*($$) {
   

    // FIX_THIS - pure duplication of code 
    const cache:DocCache  = yield $$.DocCache

        
    var docPm:CatDocContentPM<any> = cache.getCachedDoc(docId)
    var cached = (docPm != null)
  
    yield $$.Set({
        mode:MODES.EDIT,
        docId,
        cache,
        status: cached ? "ready" : "loading",
        docPm,
    })
    
    if (!cached) {
        const {ok, v, err } = yield $$.loadAndCacheCatDoc(docId)

        if (ok)  {
            docPm = v 
            yield $$.Set({status:"ready", docPm})
        } else {
            console.log(err)
            yield $$.Set({status:'load-err', error:'failed to load'})
            return // <--  are handled elsewhere
        }
        
    }
})


const setModeP = (_, $) => (_, newMode) => $.run(function*($$) {
    const pm:BibViewPM = yield $$.Get
    const {mode} = pm
    if (mode != newMode) {
        // state machine for chaning mode
        if (newMode == MODES.SEARCH || newMode == MODES.PUB) {
            const bibModel = toBibModel(pm)

            
            if (pm.bibModel !== bibModel) {
                yield $$.Set({bibModel, mode:newMode})
                return
            }
        }
        
        yield $$.Set({mode:newMode})
    } 

})



// -- search 

const setSearchP = (_, $) => searchValue => $.run(function*($$) {
    
    yield $$.Set({searchValue})

})

const searchRequestP = (_, $) => () => $.run(function*($$){
    const pm:BibViewPM = yield $$.Get
    const bibModel = pm.bibModel
    var searchResults 
    if (bibModel && bibModel.refs) {    
        searchResults = updateSearchResults(pm.searchValue, pm.searchResults, bibModel.refs) 
    }

    if (pm.searchResults !== searchResults) {
        yield $$.Set({searchResults})
    }
})

