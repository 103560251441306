import { Ref } from "./searchRefs"



export const toRefItems = refs => {
    const src = refs[0]
    const items = src.data.concat()
    items.sort(sortOnAuth)

    const refItems = items.map((ref:Ref) =>  {
        const {docName, ps} = ref
        var {ok, date, ref:shortRef} = extractDate(docName)
        if (!ok) {
            shortRef = docName
            date = " TODO - add date to ref"
        }
        const {title, auth, cite} = ps
        const authors = toAuthors(auth)
        var txt = `${authors} (${date}) "${toTitle(title)}."  ${toCite(cite)} `
        return {
            ref:clean(shortRef),
            txt
        }

    })
    console.log('---  Bibliography --- ')
    
    refItems.forEach(({txt})=> {
        console.log(txt)
        console.log(" ")
    })
    
    return refItems
}


const END_DOT = /\.$/
const WS2 = /(\s\s+)/g



const clean = str => {
    str = str.replace(END_DOT, "")
    str = str.replaceAll(WS2, " ")
    return str
}

const toTitle = txt => {
    if (!txt) {
        return " *** TODO - add title *** "
    }
    return clean(txt)
}

const toAuthors = txt => {
    if (!txt) {
        return " *** TODO - add author *** "
    }
    const auths = txt.split(';')
    return clean(auths.join(','))
}

const toCite = txt => {
    if (!txt) {
        return " *** TODO - add citation *** "
    }
    return clean(txt)
}


const DATE1 = /^(.*)(\s+[0-9]+[a-zA-Z_-]*)\s*$/
const DATE2 = /^(.*)\s+\((\s*[0-9]+[a-zA-Z_-]*)\s*\)\s*$/

const extractDate = txt => {
    for (var R of [DATE1, DATE2]) {
        var out = R.exec(txt)
        if (out) {
            return {ok:true, ref:out[1].trim(), date:out[2].trim() }
        } 
    }
    return {ok:false}
}


const sortOnAuth = (a:Ref, b:Ref) => {
    var a1 = a.ps.auth  || ""
    var a2 = b.ps.auth || ""
    if (a1 == a2) return 0
    return a1 < a2 ? -1 : 1

}
