import { ContentState, EditorState } from "draft-js"
import { Reducer } from '../../../ps/dsl';


const __EMPTY = EditorState.createEmpty() 


//  -- latest state in editor
const _globalState:{[rurl:string]:EditorState}= {}

// latest state generated from saved
const _globalBaseState:{[rurl:string]:EditorState}= {}

// last doc recieved from model form whch saved was generated from 
const _globalDoc:{[rurl:string]:EditorState}= {}

const _docCache:{[docId:string]:any} = {}



const pm0 = {   // <-- stateful
    _globalDoc, 
    _globalBaseState,
    _globalState,
}

export const CacheTerms = {
    SetDocCache: (pageURI, doc) => ({$:'SetDocCache', pageURI, doc}),
    SetDocCacheBase:(pageURI, editorState) => ({$:'SetDocCacheBase', pageURI, editorState}),
    SetCacheDocState:(pageURI:string, doc:any, state:EditorState) => ({$:'SetCacheDocState', pageURI, doc, state}),
    SetCacheGlobalState:(pageURI:string, state:EditorState) => ({$:'SetCacheGlobalState', pageURI, state})
}


export type CacheDSL = {
    SetDocCache: (id, tag, doc) => any
    SetDocCacheBase: (id, tag, editorState) => any
    SetCacheDocState: (id, tag, state) => any
    SetCacheGlobalState: (id, tag, state) => any

    CacheSelector:any //  yield $$.CacheSelectr  :: CacheSelector
} 

const url = (a,b) => `${a}/${b}`  // <-- can prefix of some sutch later

export const CacheReducer = {
    
    SetDocCache: (pm, {id, tag, doc}) => {
        _globalDoc[url(id,tag)] = doc
        return pm0
    }, 

    SetDocCacheBase:(pm, {id, tag, editorState}) => {
        _globalBaseState[url(id,tag)] = editorState
        return pm0
    }, 
  
    SetCacheDocState:(pm, {id, tag, doc, state}) => {
        const path = url(id,tag)
        _globalDoc[path] = doc
        _globalBaseState[path] = state
        return pm0
      },

     SetCacheGlobalState:(pm, {id, tag, state}) => {
        
        _globalState[url(id, tag)] = state  // <-- cache in case we navigage away
        
        return pm0
    }


}


export type DocCache = {
    EMPTY:any,
    requiresSave: (id, tag) => boolean
    setContent: (id:string, tag:string, doc:EditorState|null) => boolean
    getDoc: (id:string, tag:string) => EditorState
    getContent: (id:string, tag:string) => ContentState
    setBaseContent:(id:string, tag:string, doc:EditorState, raw:any) => void
    allIds: (requiringSave:boolean) => string[]
    allUnsaved: () => {docId:string, tag:string, content:any}[]
    nonEmpty: (docId:string,tags:string[]) => {[id:string]:boolean}

    getCachedDoc: (docId:string) => any
    setCachedDoc: (docId, doc) => void
}


var EMPTY_DOC:any
var EMPTY:any

export const CacheSelector = _EMPTY => {

    if (_EMPTY) {
        EMPTY = _EMPTY
        EMPTY_DOC = _EMPTY.getCurrentContent()    // <-- hack. fix this.
    }
    const requiresSave = (id, tag ):boolean => {
        const p = url(id,tag)
        const lastdoc:EditorState = _globalDoc[p]
        if (!lastdoc) {
            return false
        }
        const baseState:EditorState = _globalBaseState[p]  // <-- might just be the selection tht has changed
        const base:ContentState|null = baseState ? baseState.getCurrentContent() : null
        const doc = lastdoc.getCurrentContent()
        const docEmpty = doc == EMPTY_DOC
        const baseEmpty = base == EMPTY_DOC
        var requiresSave = 
         (!base && !docEmpty)  ||  // <-- any non empty content, absent a base doc
         ((base && (doc !== base ))  && !(baseEmpty && docEmpty))
            
        return requiresSave ? true : false
    }

    const allIds = (requiringSave:boolean) => {
        var ids = Object.keys(_globalDoc).filter(k => {
            const [id, tag] = k.split('/')
            return !(!requiringSave || !requiresSave(id, tag))
        })
        return ids
        
    }

    const getDoc = (id:string, tag:string):EditorState => {
        const p = url(id, tag)
        var out =  _globalDoc[p]
        if (!out) {
            out = _globalBaseState[p]
        }
        if (!out) {
            out = EMPTY
        }
        return out
    }

    return {
        DocCache: (pm):DocCache => ({
            EMPTY,
            requiresSave,

            setContent: (id:string, tag:string, doc:EditorState| null):boolean => {
                const p = url(id,tag)
                if (doc == null) {
                    delete _globalDoc[p]
                } else {
                    _globalDoc[p] = doc
                }
                return requiresSave(id, tag)
            },

            setBaseContent:(id:string, tag:string, doc:EditorState) => {
                const p = url(id,tag)
                _globalBaseState[p] = doc
                delete _globalDoc[p] 
            }, 
            allIds,
            allUnsaved: ():{docId:string, tag:string, content:any}[] => {
                const ids = allIds(true)
                var out = ids.map(k => {
                    const [docId, tag] = k.split('/')
                    const content = _globalDoc[k]
                    return {docId, tag, content}
                })
                return out
            }, 
            getDoc, 
            getContent: (id, tag) => getDoc(id, tag).getCurrentContent(),
            nonEmpty: (docId:string,tags:string[]):{[id:string]:boolean} => {
                var out: any = {}
                for (var tag of tags) {
                    const doc = getDoc(docId, tag)
                    if (doc) {
                        var content = doc.getCurrentContent()
                        if (content != EMPTY_DOC) {
                            out[tag]= true
                        }
                    }
                }


                return out
            },


            getCachedDoc: (docId:string):any => {
                return _docCache[docId]
            },
            setCachedDoc: (docId, doc):void => {
                _docCache[docId] = doc
            }
        



        })
    }
}


export const CacheDSL = () => Reducer(
    CacheTerms, CacheReducer, CacheSelector(__EMPTY), pm0, s => {
       return pm0  // <-- can't alter 
    } 
)



