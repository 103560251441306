import { EditorState } from "draft-js"
import { CatModel } from "../../../../model/cats/model/CatModel"
import { CatSchema, TagRef } from "../../../../model/cats/model/CatSchema"
import { CatsPM, createCatPM, TagPM } from "../../../../model/cats/model/CatsPM"
import { PageState } from "../../../../model/resource/PageResource"


//



export const pageToDocPath = (page:PageState<CatsPM<any>, any>):{tag:string, docPath:string, schemaRurl} => {
  const {project, user, id, tag} = page.resource.params 

  return {
    tag,
    docPath:`${project}.${user}.${id}`,
    schemaRurl:`/cats/${user}/${project}/${id}` // /cats/lea/thesis/narrative
  }

}

export type CatSchema$<vm> = {
  run: (f:Function) => Promise<any>
  create: (page:PageState<CatsPM<vm>, any>) => Promise<any>
  update: (docPath:string, state:EditorState, rurl:string, pm:CatsPM<vm>, page:PageState<CatsPM<vm>, any>) => Promise<any>

} 

// -- takes doc retrieved from firebase
export const docToCatPM = <vm>(data, rurl, createState:((raw:string) => vm)):CatsPM<vm> => {
       
   // <-- c.f. firebase 

    const { content, children, tags, title, raw } = data 
    
    const schema:CatSchema<TagRef> = JSON.parse(content)  
    const model:CatModel = CatModel(rurl, title || 'no title', schema, raw )  // <-- Q: what is title

    var feed = tags ? JSON.parse(tags) : {}
    
    const state:vm = createState(raw)     // <-- this ist the source documents (from which the schema is generated)

    var tagPMs = createTagPM(children, createState)

    const pm:CatsPM<vm> = createCatPM(model,  children, feed, raw, state, tagPMs)
  
    return pm
  }




export const createTagPM = <vm>(tags:{[tag:string]:any} , createState:(raw:string) => vm ):{[tag:string]:TagPM<vm>} => {
    var out: {[tag:string]:TagPM<vm>} = {}
    for (var tag in tags) {
      const {doc:raw} = tags[tag]
      var state = createState(raw)          //createWithDecorators(null, raw)
      
      out[tag] = {
        raw,
        state,
        base:state
      }

    }
    return out
  }
  
  
  