import { ContentBlock, EditorState, ContentState, convertToRaw } from 'draft-js';

// data CatSchema a = CatSchema .a vs:*[]?   <-- all else is deriveable from this


export type CatSchema<a> = { a:a, vs?:CatSchema<a>[]}

export const CatSchema = <a>(a:a, vs?:(CatSchema<a>[])):CatSchema<a> => ({a, vs})

export type TagRef = {tag:string, desc:string, id?:string}
export const TagRef = (tag:string, desc:string, id?:string) => ({tag, desc, id})


export const fmap = <a,b>( {a, vs}:CatSchema<a>, f:((a:a) => b)):CatSchema<b> => 
  CatSchema( f(a), vs ? vs.map(v => fmap(v, f)) : undefined)



  export type CatSchemaPM = {
    raw:any // <-- 
    schema:CatSchema<TagRef>
    isReady:boolean  
  }

// -- the problem is that it's a full on process
export const createSchemaPM = (rurl,raw):CatSchemaPM => {
  return {
    raw: raw,
    schema:CatSchema({tag:"thesis", desc:"Lea's Thesis"}),
    isReady:false
  }
}


const TAG:RegExp = /^\#([a-zA-Z_0-9\.\-]+)(\s*(.*))?$/


export const draftToSchemaDoc = (state:EditorState, docName:string):CatSchema<TagRef> => {
  let content = state.getCurrentContent()

  var items:Array<ContentBlock> = content.getBlocksAsArray()
  
  //var vs:Array<CatSchema<TagRef>> = []
  

  var i =0;
  var vs0 = items.map(block => parseLine(block))
  
  var vs = vs0.filter(v => (v.type !== "invalid"))

  //  simulate a depth first traversal

  var cats:any[] = []
  var out:any[] = []

  for (var i = vs.length -1;i  >= 0 ; i--) {
    var {type, tag} = vs[i]
      

    if (type == 'tag') {
      out.push(CatSchema(tag))
    } else if (type == 'section')  {
      out = out.reverse()
      cats.push(CatSchema(tag, out))
      out = []
    }
  }

  cats = cats.reverse()
  return CatSchema(TagRef(docName, docName, "."), cats)

}

const parseLine = (block:ContentBlock):{type:string, tag:TagRef} => {


  var type = block.getType();   
  const txt = block.getText();

  var {ok, tag} = parseTag(txt)

  
  if (!ok) {
    return {type:"invalid", tag}
  }



  if (type == "header-two") {
    return {type:"section", tag}
  } else { 
    return {type:"tag", tag}
  }
 


}

const parseTag = (txt:string):{ok:boolean, tag:TagRef} => {
  var result = TAG.exec(txt)
  var tag:TagRef

  if (!result) {
    tag =  TagRef("", txt)
    return {ok:false, tag }
  }
  const tg = result[1]
  const desc = result[2] || ""    
  tag = TagRef(tg, desc || tg, tg)
  return {ok:true, tag}
}

/**
 *  Ensure each tag has an empty state 
 */
export const updateSchemaChildren = (schema:CatSchema<TagRef>, children:{[id:string]:any}):any => {
  var out;
  var ids = {};
  var added = {}
  var removed = {}
  
  var error;

  const withIds:CatSchema<TagRef> = fmap(schema, tr => {
    var {id, tag} = tr
    if (id && ids[id]) {
      error = "duplicate id "  + id
      throw error
    }
    if ((id == undefined) || !children[id]) {
      out = out || {...children}
      id = id || tag
      
      const newDoc = {doc:emptyDraft(`Notes for #${tag}`), feed:{}}
      out[id!] = newDoc
      added[id!] = newDoc
      return {...tr, id:tr.tag}
    }

    return tr
  }) 

  if (out != undefined) {
    return {changed:true, children:out, schema1:withIds, added}
  }

  return {changed:false, children, schema1:schema, added}

} 






export const emptyDraft = txt => {
  var content = ContentState.createFromText(txt) 
  const json = JSON.stringify(convertToRaw(content))
  return json
}