import { makeStyles, Theme } from '@material-ui/core/styles';


export const styles:any = (theme:Theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  menuButton2: {
    marginLeft: 12,
    marginRight: -10,
  },
  palette: {
    primary:0x90a4ae
  },
  toolbarMargin: theme.mixins.toolbar,

  tabContainer: {
    marginLeft: "auto" // <--  pushes tabs to the right 
  }, 

  tab: {
    fontFamily: "Raleway", // <-- anything by Roboto. 
    textTransform: "none",  // <-- button defaults to uppercase 
    fontSize: "1rem",  // <-- rem instead of pixels, means same size on all screens, regardless of resolution
    minWidth: 5,    // <-- 
    marginLeft: "0px", // <- px means constant spacing regardless of device. rems would be different for different screens
                      //  px ~ specific spacings. Wheras sizing of elements, text, use responsive rems
    marginRight: "0px" // <- px means constant spacing regardless of device. rems would be different for different screens

    
  },
  tabTitle: {
    fontFamily: "Raleway", // <-- anything by Roboto. 
    textTransform: "none",  // <-- button defaults to uppercase 
    fontSize: "1.5rem",  // <-- rem instead of pixels, means same size on all screens, regardless of resolution
    minWidth: 10,    // <-- 
    marginLeft: "1px" // <- px means constant spacing regardless of device. rems would be different for different screens
                      //  px ~ specific spacings. Wheras sizing of elements, text, use responsive rems
    
  
  },

  tagItem: {
    color: (theme as any).palette.common.headingPink
  }




});


export const useStyles = makeStyles(styles)