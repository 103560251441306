import { CatSchema, TagRef } from "./CatSchema"

/**
 * deserialized category model from the database 
 */
export type CatModel = {
  
  // persisted data (possibly from multiple sources)
  rurl: string  // <-- duplicates page rurl, but handy to have it here 
  title:string  // <-- meta data mechanism 

  schema:CatSchema<TagRef> //  tree of TagRef's  
  raw:string  // <-- raw document json (draft serialization)
}

// data TagRef = TagRef tag desc id?
// data CatModel = CatModel rurl title schema:(CatSchema TagRef)
export const CatModel = (rurl:string, title:string, schema:CatSchema<TagRef>, raw:string) => 
  ({  $:'CatModel', rurl, title, schema, raw }) 