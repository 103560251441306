const firebaseConfig = {
  apiKey: "AIzaSyBOU9layGxTw3ZptTV_oF93GD2zilxfzPs",
  authDomain: "kimandlea-60df8.firebaseapp.com",
  databaseURL: "https://kimandlea-60df8.firebaseio.com",
  projectId: "kimandlea-60df8",
  storageBucket: "kimandlea-60df8.appspot.com",
  messagingSenderId: "569220215464",
  appId: "1:569220215464:web:3d74833395e4de589d489d",
  measurementId: "G-1XWYL725KK"
};

export default firebaseConfig