import { useEffect, useMemo , useState } from "react"
import { useDispatch } from "react-redux"
import { CatsPM } from "../../../../model/cats/model/CatsPM"
import {  useCurrentPage, usePage } from "../../../../model/ps/usePageLoader"
import { PageState } from "../../../../model/resource/PageResource"
import { Async, AsyncUtil, Dispatcher, dsl, State, suffixed } from "../../../../ps/dsl"
import {  pageToDocPath } from "../model/schema-model"

import firebase from '../../../../firebase'
import { ReduxActions } from "../../../../model/ReduxActions"

import { loadCatSchemaP } from "../eff/loadCatSchemaP"
import { EditorState } from "draft-js"
import { CatDocEditorPM } from "./CatDocEditor"




/**
 *  process to 
 *     
 *   - given a page resource  ::  {userName}/${project}/${id}/${tag}
 *   - 
 *   - 
 */
export const useCatDocEditorPs = ():{pm:CatDocEditorPM<any>, tag:string, $:any} => {

    const rurl = useCurrentPage() 
    const dispatch = useDispatch()
    const docPage = usePage(rurl)
    const {docPath, schemaRurl, tag} = pageToDocPath(docPage)   // <-- multiple pages might use the same schema 

    const schemaPage:PageState<CatsPM<any>, any> = usePage(schemaRurl)
  
     //const:CatsPM<EditorState> =    useCatPM(cpm.schemaRurl)


    const [pm, setPM] = useState(() => ({

            docPage,
            schemaPage,
            //doc:undefin,   // <-- set after defined   
            //schema:null, //  <-- set after defined
            
            isReady:false,
            requiresCreate:false,

             // -- page state
            editorState0: EditorState.createEmpty(),
            
            status:"not started",
            errors:[]
        }) as CatDocEditorPM<any>) 


    const ps = useMemo(() => ({
        // -- this is not well abstracted. duplicates code /w the schema loader, for instance
        '<cats[]|': firebase.db.collection('cats'),  // <-- this is where the schema is stored 
        '<cat-docs[]|':firebase.db.collection('content1')   // <-- can debate where this might best go 
    }), [])
    

    
    // -- effectively : ResourceState a = Loading | Ready a | Error err:str  
  

    const $:any = useMemo(() => {
        var $:any = {}
        const effs = suffixed({
            startP,
            // -- expose 
            changeP: (ps, $) => _ => { console.log('TODO')},
            saveP: (ps, $) => _ => { console.log('TODO')}

        },{P:[ps, $]})

        $.run = dsl([
            State(pm, setPM), // $$. Set, Get $$.set({ }) $$.get()
            Dispatcher(ReduxActions, dispatch),  // <-- effects to global redux reducer
            Async(effs),
            
            Async(suffixed({
                loadCatSchemaP,   
                loadDocP,
            }, {P:[ps,$]})),
            AsyncUtil   // -- $$.wait 
        ])

        return {...$, ...effs} 
    }, [])  


    
    useEffect(() => {      // <-- loadin
        const {cancel} = $.start()
        return cancel
    }, [rurl]) 


    useEffect(() => $.run(function*($$) {
        // update  pm in dsl  from redux pmodel

        const isReady = schemaPage.status.isReady && docPage.status.isReady
        yield $$.Set({docPage, schemaPage, schema:schemaPage.data, doc:docPage.data, isReady }) 
        // -- but need to load ... 

    }), [docPage, schemaPage])


    return {pm,  tag , $}
} // useCatDocPs



 
const startP = (ps, $) => () => $.run(function*($$) {   

    const pm0:CatDocEditorPM<any> = yield $$.Get  

    if (!pm0.schemaPage) {
        throw new Error(" TODO - handle schema ressources creation as an effect ")
    }
    if (!schemaReady(pm0)) {
        const docPath =  pageToDocPath(pm0.docPage)
        yield $$.Set({status:'loading schema'})
        yield $$.loadCatSchema(pm0.schemaPage.resource.rurl, docPath)      
    }

    const pm1 = yield $$.Get   // <-- other process will likely have updated this. 
    
    // -- corner case ... while schema was loading the url changed
    //   a more comprehensive cancellation mechanism would be nice here 
    if (pm1.docPage.resource.rurl != pm0.docPage.resource.rurl) {
        return
    }

    if (!docReady(pm1)) {   // <-- TODO - fix this.
        yield $$.Set({status:'loading doc'})    
        yield $$.loadDoc(pm1.docPage.resource.rurl)
    }

    const pm2:CatDocEditorPM<any> = yield $$.Get
    
    if (pm2.status !== "ready") {
        throw new Error("react process should have")
    }


})
 
const stop = () => {
    console.log(' stopping load document process ')
}



// -

const loadDocP = (ps, $) => rurl => { /* ps['<doc-content[]|']
    var contentRef = firebase.db.collection("content1").doc(params.id);
    contentRef.get().then((doc:any) => {
      setContent({ ...doc.data(), id: doc.id })
    })
*/

} 

// -- m


const schemaReady = (pm:CatDocEditorPM<any>) => {
    return pm.schemaPage.status.isReady 
}

const docReady = (pm:CatDocEditorPM<any>) => {
    const {isLoading} = pm.docPage.status 
    const data = pm.docPage.data
    return data && !isLoading
}