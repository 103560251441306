import { makeStyles, TextField } from "@material-ui/core";
import * as React from "react"
import { useCreateCatDocPs } from "./CreateCatDoc-ps"

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from '@material-ui/core/Container';
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from '@material-ui/core/Button';
import FaceIcon from '@material-ui/icons/Face';
import { Form$ } from "../../../ps/form/form-ps";
import { FormBase, FormOptions } from "../../../ps/form/FormBase";



type CatDocValues = {
     schemaId:string
     title:string
}

export type CreateCatDocPM = FormBase<CatDocValues> 


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



export const CreateCatDoc = () => {

    const classes:any = useStyles();

    const {pm, $}:{pm:CreateCatDocPM, $:Form$} = useCreateCatDocPs()
    
 

    // selection api: https://material-ui.com/components/selects/
    return  (
    <div>
        <h2> Create a category-structured Documnet</h2>
        {!pm.options || !(pm.options!.schemaId) ? ( // <-- TODO - proper state mechanism
          <div>...loading schemas</div>
        ) : ( 
          <div>
            {pm.err ? <h3>Error: {pm.err}</h3> : null }
            {BaseForm(classes, pm, $)}
          </div>)
        }

        
        </div>
/*
        <form className={classes.root} noValidate autoComplete="off">

            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="schemaId-native-helper">Categories for this document</InputLabel>
            <NativeSelect
            value={state.schemaId}
            onChange={handleChange}
            inputProps={{
                name: 'schemaId',
                id: 'schemaId-native-helper',
            }}
            >
                {schemaOptions(pm.schemas)}
        
            
        
            </NativeSelect>
            <FormHelperText>Select the category schema with which to structure your document</FormHelperText>
        </FormControl>
        <TextField id="filled-basic" label="Filled" variant="filled" />
        <TextField
          id="titl"
          label="Titl"
          defaultValue="..."
          helperText="title "
          variant="filled"
        />
      
        </form> 
    </div>*/)
}


const schemaOptions = (schemas?:FormOptions[]) => {
    if (schemas) {
        return (<React.Fragment>
            {schemas!.map(({id}) => (<option key={id} value={id}>{id}</option>)) }
        </React.Fragment>)
    } else {
        return <option aria-label="None" value="loading schemas ..."> loading ... </option>
    }
}





const BaseForm =  (classes, pm:FormBase<CatDocValues>, $:Form$) => {

    const {values, options, formSchema:schema, formErrors:errors} = pm

  
    return(
      <Container component="main" maxWidth="xs">
  
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FaceIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            
          </Typography>
          <form className={classes.form} noValidate onSubmit={$.submit} >
            {schema.map( (vs ) => {
            const {id, label, type,  desc , autocomplete , isPassword} = vs

            //var err = errors ? errors[id] : undefined
            if (type == "selector" ) {
              return (
              <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="schemaId-native-helper">{label}</InputLabel>
                  <NativeSelect
                    id={id}
                    value={values[id]}
                    onChange={$.change}
                    error= {errors && errors![id] != null }
                    inputProps={{
                        name: id,
                        id: 'schemaId-native-helper',
                    }}
                  >
                  {schemaOptions(options![id] as any)}
                </NativeSelect>
                <FormHelperText>{desc}</FormHelperText>
              </FormControl>)
            } else {
              return (

                <TextField
                  id={id}
                  name={id}
                  error= {errors && errors![id] != null}
                  value={values[id]}
                  onChange={$.change}
                  autoComplete={'off'}
                  helperText={errors && errors![id] || desc}
                />)

              }
            })}
          
             
 
            <Button 
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={pm.isSubmitting}
            >
              {pm.isSubmitting ? "...Submitting " : "Create"} 
            </Button>

          </form>
        </div>
     
      </Container>
    )}
  
  