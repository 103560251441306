
const WS:RegExp = /^\s*$/

const isWS = s => WS.test(s)

var __uid = 1
const uid = () => {
    return (__uid++).toString()
}

/**
 * 
 */
export const parseRefs = (txt) => {
    const out:any[] = []
    
    // --  remove empty lines
    var lines = txt.split('\n')
    lines = lines.filter(line => !isWS(line))
    const sections = toSections(lines)
    var section:any
    for (section of sections) {

        var data = toData(section.lines)
        out.push({title:section.title, ignore:section.ignore, data, uid:uid()})
    }
    return out

}

const toSections = (lines) => {
    // -- break into sections
    var sections:any[] = []
    var current:any = null

    lines.forEach((line, i) => {
        const {ok, ignore, title} = matchSection(line)
        if (ok) {
            if (current) {
                sections.push(current)
            } 
            current = {title, ignore, lines:[]}
            
        } else {
            if (!current) {
                throw new Error(`expecting a section, ie >> Section, in line ${i}`)
                //current = {title:"??", lines:[]}
            }
            current.lines.push(line)
        }

    });

    if (current && current.lines.length > 0) {
        sections.push(current)
    }
    return sections
}

const toData = lines => {
    var len = lines.length
    var i = 0;
    var out:any[] = []
    while (i < len) {
        var line = lines[i]
        i++
        const {ok, docName} = toDocName(line)
        if (!ok) { 
            throw new Error(` expecting doc name in ${line}`)
        }
        var data:any = {docName, uid:uid(), ps:{}} 

        while (nextLineIsProp(lines, i)) {

            const {ok, name, value} = matchProp(lines[i])
            if (!ok) {
                throw new Error(`couldn't get property from ${lines[i]}`)
            }
            
            data.ps[name!] = value
            i++
        }
        out.push(data)
    }
    return out
} 


const DOC = /\>\s+(.*)\s*/

const toDocName = line => {
    const match = DOC.exec(line)
    if (match) {
        const docName = match[1]
        return {ok:true, docName}
    }
    return {ok:false}
}

const PROP = /\s+([a-z1-9_A-Z\[\]]+)\:\s*(.*)\s?/

const nextLineIsProp = (lines, i) => (lines.length > i && PROP.test(lines[i]))

const matchProp = line => {
    const match = PROP.exec(line)
    if (match) {
        const name = match[1]
        const value = match[2]
        return {ok:true, name, value}
    }
    return {ok:false}



}


const sec  = /^\>\>(\>)?\s+(.+)\s*$/
const matchSection = s => {
    var out = sec.exec(s)
    if (out && out.length > 1) {
        const ignore = out[1]
        const title = out[2]
        return {ok:true, ignore, title}
    }
    return {ok:false}

}



