import { convertToRaw } from "draft-js"
import { useMemo, useRef } from "react"
import { useDispatch } from "react-redux"
import { CacheDSL, DocCache } from "../../components/Categories/CatDocsContent/DocCache-dsl"
import firebase from "../../firebase"
import { ReduxActions } from "../../model/ReduxActions"
import { UIState } from "../../model/ui/ui-pmodel"
import { useUI } from "../../model/ui/useUI"
import { Async, AsyncUtil, Dispatcher, dsl, Err, Selectors, suffixed } from "../../ps/dsl"
import {saveP} from "../../components/Categories/CatDocsContent/eff/saveP"  
// -- temporary hack. Until this is incorporated in the page uri

const _STATE_CACHE:any = {}   // doc => {isOpen, folderOpen}



export const useAppUi = ():{pm:UIState, $:any} => {
    const pmRef:any = useRef(null)
    const dispatch = useDispatch()
    const pm:UIState = useUI()   

    pmRef.current = pm

    const $ = useMemo(() => {
        const ps = {
            '<CatDoc[]|':firebase.db.collection('CatDoc')  // <-- dependency c.f $.save 
        }
        const $:any = {}
        const eff = suffixed({
            toggleDrawerP,
            toggleMenuP,
            saveP,
            toggleShowBaseP,
            quoteP
        }, {P:[ps,$]})

        $.run = dsl([
            
            Dispatcher(ReduxActions, dispatch, Selectors({UIState: pm => pm}, pmRef)),
            CacheDSL(),
            Async(eff), AsyncUtil

        ])


        return {...$, ...eff }
    }, [])



    
    return {pm, $}
    
}


const toggleMenuP = (_, $) => () =>  $.run(function*($$) {
    const pm = yield $$.UIState
    yield $$.OPEN_DRAWER(!pm.drawerOpen)
})


const toggleDrawerP = (_, $) => () => $.run(function*($$) {
    const pm = yield $$.UIState
    yield $$.SET_MENU(!pm.menuOpen) 
})



const toggleShowBaseP = (_, $) => () => $.run(function*($$) {
    yield $$.SET_SHOW_BASE_DOC()
})

const quoteP = (_, $) => o => $.run(function*($$){
    const {docId, tag, sel} = o
    // -- FIX_THIS - architectural hack. Need a mechanism to route the action to the 
    //    appropriate process context
    o.$.run(function*($$) {
        yield $$.quote(docId, tag, sel)  // FIX_THIS --- very hacky
    })
})