import { CatSchema, emptyDraft, TagRef, updateSchemaChildren } from "../../../../model/cats/model/CatSchema"
import { CatsPM, createCatPM } from "../../../../model/cats/model/CatsPM"
import { createSchemaContent } from "../../../../model/doc/backend/fb-content"
import { PageState } from "../../../../model/resource/PageResource"
import { createWithDecorators } from "../../../../muiEditor/decorators"
import { createViewModel } from "../model/createViewModel"
import { createTagPM } from "../model/schema-model"

export const createP = (ps, $) => <vm>(rurl, page:PageState<CatsPM<vm>, any>) => {
  

    const {project, user, id} = page.resource.params
    const docPath = `${project}.${user}.${id}`



    // -- creating empty doc 
    var schema = CatSchema(TagRef(".",id)) 
    var raw = emptyDraft("-- enter you category schema -- ")
    
    const {children, schema1} = updateSchemaChildren(schema, {})
    const initialContent = createSchemaContent(user, page, JSON.stringify(schema), children, raw)
    const state = createWithDecorators(null, raw)  

    var  tagsPM  = createTagPM(children, createViewModel)
    throw "TODO - implement create cat pm"

    
    const pm = null   // createCatPM(rurl, id, schema1, children, {}, raw, state, tagsPM) 

    // -- here
    return ps['<cats[]|'].doc(docPath)
      .set(initialContent).then(() => $.run(function*($$) {

        yield $$.SetCat(pm)
      })
    ).catch(e => {
      console.log(" TODO - deal with error")
      throw new Error(`error ${e}`)
    })

  
}




