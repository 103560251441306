import * as React from 'react'
import Link  from 'redux-first-router-link';

import { CompositeDecorator, DraftDecorator, EditorState, convertFromRaw } from "draft-js"




const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
  contentBlock.findEntityRanges(
      (character: any) => {
          const entityKey = character.getEntity()
          return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === 'LINK'
          )
      },
      callback
  )
}




const findDecoWithRegex = (regex: RegExp, contentBlock: any, callback: any) => {
  const text = contentBlock.getText()
  let matchArr, start
  while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      callback(start, start + matchArr[0].length)
  }
}






export const createWithDecorators = (additionaDecorators, defaultValue) => {
    

  const decorators: DraftDecorator[] = [
    {
        strategy: findLinkEntities,
        component: Link,
    }
  ]

  const decorator:any = new CompositeDecorator(decorators)

  

  if (additionaDecorators) {
    additionaDecorators.forEach((deco:any) => decorators.push({
        strategy: (contentBlock: any, callback: any) => {
            findDecoWithRegex(deco.regex, contentBlock, callback)
        },
        component: deco.component
    }))
  }
  var o:any
  try {
    var rawo = JSON.parse(defaultValue)
    o = convertFromRaw(rawo)
  } catch (e) {
    console.log('cannont parse  ', {e,defaultValue})
    throw e
  }
 

  var editorState = EditorState.createWithContent(o, decorator)

  return editorState

}
