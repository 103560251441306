import { CatsPM } from './cats/model/CatsPM';
import { UIState } from './ui/ui-pmodel';
import { PageState, setPage, createPageResource } from './resource/PageResource';
import { UserState } from './user/UserState';
export type int = number;


export type Model = {
  page?: PageState<any, any>;
  user:UserState
  cats?: CatsPM<any>;
  uiState: UIState;
  resources: { [path: string]: PageState<any,any> };
  config: {
    repo: string;
    ver:number;
  };
  requireSave:boolean
};



export type AppState = {
  app:Model
}

//const repo =  
const repo =  (process.env.NODE_ENV == 'production') 
   ?  "https://kimandleabook.cafetextual.com"  
   :  "http://localhost:3000"
  

export const defaultState = (): Model => {
  const state = {
    page: null,
    user:{ 
      user:null,
      isLoading:false
    },
    uiState: {
      menuOpen: false,
      drawerOpen: false,
      requireSave: false,
      saveInProgress: "no"
    },
    resources: {},
    config: {
      repo
    },
    requireSave:false
  } as any as Model

  const page = createPageResource(state, "home", "/",{}, {})

  var out = {...setPage(state, page), page}
  
  return out

}

