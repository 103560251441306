import * as React from 'react'
import {  Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Avatar, Container, TextField } from "@material-ui/core";
import FaceIcon from '@material-ui/icons/Face';
import Link from "redux-first-router-link";

import {  makeStyles } from '@material-ui/core/styles';
import { form} from './registration-styles'
import { FormBase } from '../../ps/form/FormBase';
import { Form$ } from '../../ps/form/form-ps';



const useStyles = makeStyles(form as any);


export const BaseForm = (pm:FormBase<any>, $:Form$) => {
    
    const classes:any  = useStyles()
    //const {  onChange, onBlur, onSubmit, values, errors, isSubmitting } = formProps

    const {formErrors:errs, formSchema, values, isSubmitting, hasError} = pm

    return(
      <Container component="main" maxWidth="xs">
  
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FaceIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            
          </Typography>
          <form className={classes.form} noValidate onSubmit={$.submit} >
            {formSchema.map( ({id,label, value ,autocomplete , isPassword}) => {
              var err:any = errs ? errs[id] : undefined
            return (
                <TextField key={id}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id={id}
                label={label}
                value={value}
                onChange={$.change}
                error={ err! ||  false}
                helperText = {err || err}
                type={isPassword ? 'password' : undefined }
                autoComplete={autocomplete || "off"}
                autoFocus
                />
  
            )})}
            
            
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/forgot">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="login">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
       
      </Container>
    )}
  
  



    const displayErrors = errors => errors.map((error, i) => <p key={i}>{error.message}</p>);
