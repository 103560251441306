import { ContentState, EditorState } from "draft-js"
import { CatDocContentPM } from "../../../Categories/CatDocsContent/CatDocContent-model"
import { BibViewPM } from "../Bibliography-ps"
import { toRefItems } from "./genBib"
import { parseRefs } from "./parseRefs"



export type BibModel =  {
    parsedFrom:ContentState
    text:string 
    refs?:any
    parseErr?:string
    pub:any
    refItems:{ref:string, txt:string}[]
    refTxt:string[]
} 

const TAG = "_"
export const toBibModel = (pm:BibViewPM):BibModel => {
    const {docId, cache, docPm, bibModel} = pm

    const content:ContentState = cache!.getContent(docId!, TAG)


    if (bibModel && bibModel.parsedFrom == content) {
        return bibModel
    }

    const text = content.getPlainText("\n")
    console.log(`content:  "${text}"`)
    if (bibModel && bibModel.text === text) {
        return bibModel
    }
    var refs 
    var parseErr 
    var pub

    try {
        refs = parseRefs(text)
    } catch (e) {
        parseErr = e.message 
    }

    const refItems = toRefItems(refs)
    const refTxt = refItems.map(({txt}) => txt)
    // -- gen   
    return {
        parsedFrom:content,
        text,
        refs,
        parseErr, 
        pub, 
        refItems,
        refTxt
    } 
}