import { CatDoc } from "../../CatDocs/CatDoc-pm"

export const getCatDocP = (ps, $) => id => $.run(function*($$) { 
    
    const r = yield $$.result((Ok, Err) => {
        ps['<CatDoc[]|'].doc(id).get().then((docSnap:any) => {
            if (!docSnap.exists) {
                Err("does not exist")
            } else { 
                const data = docSnap.data()
                const id = docSnap.id
                const doc:CatDoc = { ...data, id}  
                Ok(doc)
            }
        })
        .catch(Err)
    })
    return r
})

 


