import { UIState } from "../../../../../model/ui/ui-pmodel"
import { ContentState, EditorState, Modifier, SelectionState } from "draft-js"
import { DocCache } from "../../DocCache-dsl"

import { BASE_DOC_ID } from "../../CatDocsTest-ps"
import {DocSelectPM} from "../../DocSelectPM"
    
export const doQuoteP = (_, $) =>  () => $.run(function*($$) {
    const ui:UIState = yield $$.UIState
    const {canQuote} = ui
    if (canQuote) {
        const {docId, tag, sel} = canQuote  
        yield $$.quote(docId, tag, sel)
        console.log('x')
    }   
})


export const quoteP = (_, $) => (docId, tag, sel) => $.run(function*($$) {

    if (docId == BASE_DOC_ID) {

        const cache:DocCache = yield $$.DocCache

        const doc:EditorState = cache.getDoc(docId, tag)
        // const sel:SelectionState = doc.getSelection() <-- issue /w it not persisting
        //const content = doc.getCurrentContent()

        if (doc && sel && !sel.isCollapsed()) {


                
            const quote = getCurrentTextSelection(doc, sel)  // <-- extract plain text. but only from 1st block


            if (quote) {

                // -- finally, need to foce a re-render 
                const pm:DocSelectPM = yield $$.Get
                const docId = pm.docPm!.doc.id
                const targetDoc:EditorState = cache.getDoc(docId, tag)
                const targetSel:SelectionState = targetDoc.getSelection()
                
                const newDoc = insertText(quote,targetDoc, "STRIKETHROUGH")
            
                yield $$.change(docId, tag, newDoc)
                //cache.setContent(docId, tag, newDoc)

                const {docPm} = pm
                yield $$.SET_CAN_QUOTE(false)
                yield $$.Set({docPm: {...docPm}})    // <-- Kind of hacky. But this forces a re-render 
           
            }
        }





        const pm:UIState = yield $$.UIState

        if (!pm.showBaseDoc) {
            yield $$.SET_SHOW_BASE_DOC(true)
        }
    }   

})



// -- document manipulation mechansims 


const getCurrentTextSelection = (editorState: EditorState, selectionState): string => {
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const txt = currentContentBlock.getText()
    const selectedText = txt.slice(start, end);
  
    return selectedText;
  };

  const insertText = (text, editorState, inlineStyle) => {
    const currentContent = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection();


    
    // -- need to reproduce the selection
    var selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();

    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    const txt = currentContentBlock.getText()
    var selectedText = txt.slice(start, end);


    var newContent:ContentState = Modifier.replaceText(
        currentContent,
        currentSelection,
          `   "${text}"   `
      );

    const newSelection = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: start + 3,
        focusKey: anchorKey,
        focusOffset: start  + text.length + 5,
      });

    newContent = Modifier.applyInlineStyle(newContent, newSelection, "QUOTED" )

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    // check to see what's now selected
    //const newquote = getCurrentTextSelection(newEditorState)
    //console.log(newquote)
    
    // could alter style ?? 
    //Modifier.applyInlineStyle
    const postSelection = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: start  + text.length + 8,
        focusKey: anchorKey,
        focusOffset: start  + text.length + 8,
      });



    return EditorState.forceSelection(newEditorState, postSelection);
  }

