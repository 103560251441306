import * as React from 'react'

import { PageState } from "../../model/resource/PageResource";
import Link from "redux-first-router-link";
import Project from "../Project/Project";
import Notes from "../Project/Notes/Notes";
import { Register} from "../Auth/Register";
//import DocEditor from "../Doc/DocEditor";
import DocEditorTest from "../Doc/DocEditorTest";
import CreateLink from "../Link/CreateLink";
import ForgotPassword from "../Auth/ForgotPassword";
import LinkList from "../Link/LinkList";
import { LinkList2 } from "../Link/LinkList2";

import LinkDetail from "../Link/LinkDetail";
import { CatDocEditor } from '../Categories/CatSchema/CatDoc/CatDocEditor';
import { EditCatSchema } from '../Categories/CatSchema/CatSchemaEditor';
import { Divider } from '@material-ui/core';
import { CreateCatDoc } from '../Categories/CreateCatDoc/CreateCatDoc';
import { CatDocs } from '../Categories/CatDocs/CatDocs';
import { CatDocsTest } from '../Categories/CatDocsContent/CatDocsTest';
import { SearchConfig } from '../Search/SearchConfig';
import { Bibliography } from '../Project/Bibliography/Bibliography';
import { Login } from '../Auth/Login';
import { FBUser } from '../../ps/auth/User-dsl';


export const renderPage = (page: PageState<any, any>, user?:FBUser) => {

  let { type, rurl } = page.resource
  
  switch (type) {
    case "home":
      console.log("rendering home)");
      return (
        <div>
          {!user && <Link to="login">login</Link>}

         <div>Hi Lea,  </div>
          <div>{" "}</div>
          <h1>{" "}</h1>
          <div>  <Link to={"/bibliography/lea/thesis"}> Bibliography </Link> </div>
         
          
          <div>
            <div> {"<"}-- Click on these to see notes  </div>
            <div> ie.  <Link to={"/cat-docs-view/cnotes"}> Conclusion Notes </Link> </div>

          
          </div>

          <div>

          <div> v7 of your thesis is here also: </div>
          <div>  <Link to={"/cat-docs-view/thesis-vs"}> Thesis v7 </Link> </div>

          </div>

            <hr className="solid"/>

          <div>
            Don't bother about any of the rest of this
          </div>


          <div>  <Link to={"/create-cat-doc"}> doc admin </Link> </div>



          <div>  <Link to={"/register"}>register </Link> </div>
          <div>  <Link to={"/login"}>login </Link> </div>


          <h1>Categories </h1>
          <div>  <Link to={"/cats/lea/thesis/thesis-categories"}> Lea's thesis categories </Link> </div>
          <div>  <Link to={"/cats/lea/thesis/narrative"}> Ian's narrative categories </Link> </div>


          <h1>{" "}</h1>
          
          <Divider />
          <div>{" "}</div>
          <h1>{" "}</h1>

          <h1>Bibliography</h1>
          <div>{" "}</div>
          <h1>{" "}</h1>
          <div> <Link to={"/project/ian/ian-temp"}>temp</Link>{" "} </div>

          <div> <Link to={"/project/lea/thesis"}>thesis</Link>{" "} </div>
          <div> <Link to={"/project/lea/essay"}>essay</Link>{" "} </div>    
          <div>{" "}</div>
          <h1>{" "}</h1>

          <Divider />

        

          <div>
              <div>{" "}</div>
              <h1>{" "}</h1>
              <h1> Notes:</h1> 
              <div> And this section is the documents that distill quotes and such from the bubble notes</div>
              <div>     
                
                 <Link to={"docs/lea/winnicott"}>notes on Winnicott</Link> {"  "}
                 <div>

                 <Link to={"docs/lea/klein"}>notes on Klein</Link> {"  "}
                 </div>
              </div>

              <Link to={"docs/lea/all"}>other stuff (ignore for now)</Link> {"  "}
                       <Link to="/register">register</Link>

          </div>

          {user && (user.name == "ism")  &&  
          
          <div>
            <Link to="/register">register</Link>

            <h1>{" "}</h1>
            <h2>admin</h2>
            <div>
              <Link to={"create/categories"}>  create categories </Link>{" "}
              <Link to={"catlist/thesis"}> list of categories</Link>
                  
              create Notes on:

              <Link to={"create/winnicott"}> winnicott </Link>{" "}
              <Link to={"create/klein"}> klein </Link>{" "} 
              
              <Link to={"create/essay"}> essay </Link>{" "}
              <Link to={"create/thesis"}> thesis </Link>{" "}
              <Link to={"create/epistemic"}> epistemic  </Link>{" "}
          </div>
            <div>
            <Link to={"testdoc/quote2"}>quote2 </Link>{" "}
            <Link to={"register"}>register </Link>{" "}
          </div>
          </div>}
        </div>
      );

    // -- TODO - edit category
    case "cats":
      return <EditCatSchema user={user!}/>
    case "project":
      return <Project rurl={rurl} />;
    case "bib":
      return <Bibliography />
    case "ref":
      return <Notes rurl={rurl} />;
    case "login":
      return <Login />;
    case "register":
        return  <Register/>
    case "forgot":
      return <ForgotPassword />;
    case "create":
      return <CreateLink />
    case "docs":
        return <LinkList/>
    case "doc":
      return   <div> TODO - fix DocEditor component </div>
    case "cat_doc":
      return <CatDocEditor/>
  
    case "testdoc":
      return <DocEditorTest/>
    case "link": 
      return <LinkDetail/>;
    case "catlist":
      return <LinkList2 /> 
    case "create_cat_doc":
      return <CreateCatDoc />
    case "cat_docs":
        return <CatDocs/>
    case "cat_docs_view": 
        return <CatDocsTest/>
    case "404":
        return <div>Link not found </div>
    case "search":
        return <SearchConfig/>

    default:
      return <h1> No component found </h1>;
  }
};