import { ContentState } from 'draft-js/';
import {  SET_REQ_SAVE } from '../ReduxActions';
import { CatsPM } from './model/CatsPM';
import { take, all, takeEvery, put, select} from 'redux-saga/effects'


import {EditorState } from 'draft-js'


const delay = (ms) => new Promise(res => setTimeout(res, ms))



export function* categoriesSaga() {
  
  yield all([
    takeEvery("CAT_LOAD", catPreload),
    takeEvery("CAT_CHANGE", catChange),
    takeEvery("CAT_RENDER", catRender),
    takeEvery("CAT_SAVE", catSave)
  ])


} 

function* catPreload(v) {
  // -- loading   
  console.log('x')
  const action  = yield put({ type: 'CAT_SET' })

}

var _cache = {}


function* catChange(action) {

  console.log('x')
  let {pm, id, state, isInitial} = action 
  const rurl = pm.rurl
 
  const prevDoc:ContentState = yield select(s => {
    return s.doc.cache[rurl]
  })

  const saveRequired = yield select(s => s.app.saveRequired)

  const doc = state.getCurrentContent()
  if (!prevDoc || prevDoc != doc) {
     yield put({type:"CACHE_CAT", rurl, doc})  
     if (!saveRequired) {
          throw new Error(" TODO - redux saga possibly abandoned")
      // yield put(SET_REQ_SAVE(true) )
     }
  }
  //if (!requiresSave) {
  //  yield put(SetSave(true))
 // }
  return
} 


/**
 * 
 *
 */
function* catRender(action) {
  

  return 
}

function* catSave(action) {
  

  
  return
}