import { TagFeed } from '../TagFeed';

import { CatModel } from './CatModel';
import { CatItem, toCatItems } from './CatItem';


// -- flattened  list of tages (for easy menu rendering)
// data CatItem = CatItem rurl tag label smallLable depth    <-- convenient for view rendering
// data TagPM vm = raw:string base:vm state:vm      
// data CatsPM vm = CatsPM model:CatModel cats:CatItem[] tags:TagFeed 

// data TagFeed :: [tag:string] => TagFeedItem   
// data TagFeedItem


export type CatsPM<vm> = {

  model:CatModel    // <-- this is where the schema lives

  cats: CatItem[]  // <-- flattened tag schema, convenient for view (non-recursive) rendering 
  tags:TagFeed

  //tagedByDoc: {[docID:string]:boolean}
  //taggedByNote: {[noteID:string]:boolean}

  // -- modle and 


  children: {
    tags:{[tag:string]:any}  // <-- model    // <-- not yet implemented
    tagPMs: {[tag:string]:TagPM<vm>} // <-- 
    hasChanged:boolean           // 
  }


  state?:vm // <-- editor representation (ie. draft-js, no need to couple this to the model, though could paramaterize)

}


export type TagPM<vm> = {  // <-- parametic in vm ~ view model (ie draft js EditorState)
  raw:string
  base:vm                 // <-- last loaded or saved
  state:vm                // <-- current
}




/**
 *  create the categories pmodel 
 * 
 *   - paramaterized in vm ~ view model)
 */
export const createCatPM = <vm>(model:CatModel, children:{[tag:string]:any}, tags:any, raw:string, state:any, tagPMs:{[tag:string]:TagPM<vm>} ):CatsPM<vm> => {

  const {rurl, title, schema} = model
  // -- this is generated

  const cats = toCatItems(rurl, schema)


  return {
    model,  
    tags, cats, state, 

    children:{
      tags:children,
      tagPMs:tagPMs,
      hasChanged: childrenChanged(tagPMs)
    }
    
  } 

}

/**
 *  compares tag view model bas and current states to determine if there are any changes
 * 
 */
const childrenChanged = (pms:{[tag:string]:TagPM<any>}):boolean =>  
  Object.keys(pms).reduce((acc:boolean, tag:string) => (acc || (pms[tag].base !== pms[tag].state )), false)
