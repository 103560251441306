import { CatSchema, TagRef } from "./CatSchema"

export type CatItem = {
    rurl:string  // <-- use this to reference page
    //path:string  // <-- relative to the main category type
    tag:string 
    //page:PageState<any,any>
    label:string
    smallLabel: string
    depth:number
    schema:CatSchema<TagRef>
    title:string
    children?:string[]
  }

   

  /**
 *  Flat list presentation model of tags/categories
 */
export const toCatItems = (rurl:string,  schema:CatSchema<TagRef>):CatItem[] => {
    // TOOD - implement /w proper recursion scheme
    var out:CatItem[] = []
  
    // -- add reference to main schema itself   
    console.log('x')
    let {tag:tag0, desc} = schema.a
      
  var topItem:CatItem = {
      rurl,  // <-- this is the url back to the schema editor 
      tag:"_",
      label:`-- ${desc} --`,
      smallLabel: '--',
      depth:0 ,
      title:desc,
      schema,
      children:[]
    }
    
    out.push(topItem)
    var children:string[] = topItem.children!
    const sections = schema.vs || []

    for (var i = 0; i < sections.length; i++) {
      //const secNum = toRoman(i +1, true)
  
      var cat:CatSchema<TagRef> = schema.vs![i];
      let {tag:secTag, desc:secDesc} = cat.a
      children.push(secTag);

      const secItem:CatItem = {
        rurl: `${rurl}/${secTag}`,
        tag:secTag,
        smallLabel: `#${secTag}`,
        label: `#${secTag}. ${secDesc}`,
        depth:1,
        title:secDesc,
        schema:cat,
        children:[]
      }
      out.push(secItem)

      const childTags = cat.vs || []
      for (var j = 0; j < childTags.length; j++) {
       // const tagNum = toRoman(i +1)
        const childSchema = childTags[j]
        const {tag, desc} = childSchema.a
        secItem.children!.push(tag)
        out.push({ 
          rurl: `${rurl}/${tag}`,
          tag,
          smallLabel: `#${tag}`,
          label: `#${tag}. ${desc}`,
          depth:2,
          title:desc,
          schema:childSchema
        })
  
      }
  
    }
  
    return out
  
  }
  
  
  