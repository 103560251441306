// configure redux-first routeing
// see:  https://blog.logrocket.com/why-you-dont-need-to-mix-routing-state-with-redux/


// configureStore.js
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { connectRoutes } from 'redux-first-router'
import queryString from 'query-string'
import createSagaMiddleware from 'redux-saga'
import {routesMap, pageReducer}  from './model/pageReducer'
import modelReducer from './model/reducer'
//import createHistory from 'history/createBrowserHistory'
import createHistory from "rudy-history/createBrowserHistory";
import { categoriesSaga } from './model/cats/cats-saga'
import { docReducer } from './model/doc/docReducer'


//const history = createHistory()
//const history = createHistory()

// -- serialize state reducer
const reducers =(state, action ) => {
  var out = modelReducer(state, action) 
  if (out === state) {
    out = pageReducer(state, action)   // <-- only run page reducer if model hasn't taken this
  }

  return out
}

  const sagaMiddleware = createSagaMiddleware()

export default function configureStore(preloadedState) {
  const { reducer, middleware, enhancer } = connectRoutes( routesMap,  
    { querySerializer: queryString}//, createHistory}
  )



  const rootReducer = combineReducers({ app:reducers, location: reducer, doc:docReducer })
  
  
  const middlewares = applyMiddleware(middleware, sagaMiddleware )


  const enhancers = compose(enhancer, middlewares)

  const store = createStore(rootReducer, preloadedState, enhancers)
  sagaMiddleware.run(categoriesSaga)


  return store
}

