import { CatActions } from "./cats/cats-actions"
import { NavActions } from "./pageReducer"
import { UserActions } from "./userReducer"


// generatable from 
//  LOAD_PROJECT owner project
//  SET_SAVE_REQ v:any
//  SET_DOC rurl doc:any     <-- doc type dependent on rurl 


export const ReduxActions = {
  // -- not sure
  LOAD_PROJECT:(owner: string, project: string) => ({type: "LOAD_PROJECT", owner,  project}),
  SET_DOC: (rurl, doc) => ({type:"SET_DOC", rurl, doc}),


  // -- Page Load Actions
  LOAD_START: page =>  ({ type: "LOAD_START", page}),
  LOAD_COMPLETE: (page, data) => ({type: "LOAD_COMPLETE", page, data }),
  LOAD_ERROR: (page, e) => ({ type: "LOAD_ERROR", page, e }),

  // -- user 
  ...UserActions,

  // -- ui
  SET_MENU: (open: boolean) => ({ type: "SET_MENU", open }),
  SET_DRAWER: (open: boolean) => ({ type: "SET_DRAWER", open }),
  SET_REQ_SAVE: v => ({type:'SET_REQ_SAVE', v}),
  SET_SAVE_IN_PROGRESS: (v:"yes"|"no"|"ok"|"err") => ({type:'SET_SAVE_IN_PROGRESS', v}),
  SET_SHOW_BASE_DOC: () => ({type:'SET_SHOW_BASE_DOC'}),
  SET_CAN_QUOTE: v => ({type:"SET_CAN_QUOTE", v}),
  ...CatActions,
  ...NavActions

 
}