import { UIState } from './ui-pmodel';

export const uiReducer = (state: UIState, action) => {
  const { type } = action 
  
  switch (type) {
    case "SET_MENU": 
      return {...state, menuOpen:action.open}
    case "SET_DRAWER":
      return {...state, drawerOpen:action.open}
    case "SET_REQ_SAVE":
      return {...state, requireSave:action.v}
    case "SET_SAVE_IN_PROGRESS":
      return {...state, saveInProgress:action.v}
    case "SET_SHOW_BASE_DOC":
      return {...state, showBaseDoc:!state.showBaseDoc}
    case "SET_CAN_QUOTE":
      return {...state, canQuote:action.v}
  }
  return state
}