import { CatDocContent } from "../CatDocContent-model"

export const loadCatDocContentP = (ps, $) =>  id => $.run(function*($$) {
    const r = yield $$.result( (Ok, Err) => {
        // -- CatDoc/:id/content:
        ps['<CatDoc[]|'].doc(id).collection('content').get().then(querySnap => {
            var out:{[id:string]:CatDocContent<any>} = {}
            querySnap.forEach(doc => {

                const id = doc.id
                const {raw, created, updated} = doc.data()
                const vm = JSON.parse(raw)  // <-- not quite the view model we're looking for
                const content = {
                    id, raw, created, updated, vm 
                }
                out[id] = content
            })
            Ok(out)

        }).catch(Err)

    })

    return r
})
