import { getDefaultKeyBinding, KeyBindingUtil } from "draft-js";

export const keyP = (_, $) => (event) => {
    // we press CTRL + K => return 'bbbold'
    // we use hasCommandModifier instead of checking for CTRL keyCode because different OSs have different command keys

    const  cmd:boolean = KeyBindingUtil.hasCommandModifier(event)
    const  ctrl:boolean = KeyBindingUtil.isCtrlKeyCommand(event)

    const code = event.keyCode
    console.log(`key = ${code}`)
    if (cmd &&  code === 83) { // cmd-s  
        event.preventDefault()
        $.run(function*($$) {
            yield $$.save() 
        })
        return 'handled';
    } else if ( 
            (ctrl && code === 81)  || (cmd && code === 68  ))  {  // ctr
        event.preventDefault()
        $.run(function*($$) {
            yield $$.doQuote()    // <-- mechanism to require this
        })
        return null //'handled';
    }



// manages usual things, like:
// Ctrl+Z => return 'undo'
return getDefaultKeyBinding(event);
}