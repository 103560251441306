import { EditorState } from "draft-js"
import { CatsPM } from "../cats/model/CatsPM"

/**
 * Doc State 
 *  o handles  
 * 
 * 
 */
export type DocState = {
  cache:{[rurl:string]:{pm:CatsPM, state:EditorState}}
  lastSaved:{[rurl:string]:{ raw:string, state:EditorState }}
}  

export const docReducer = (state:DocState, action):DocState => {
  if (!state) {
    return defaultDocState()
  }
  switch (action.type) {
    case "CACHE_CAT":
      const {rurl, doc} = action
      return {...state, cache:{...state.cache, [rurl]:doc}}

  }
  

  return state
}


const defaultDocState = ():DocState => ({
  cache:{},
  lastSaved:{}
})