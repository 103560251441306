import * as React from "react"
import { Editor, EditorState, ContentState, SelectionState, KeyBindingUtil, getDefaultKeyBinding} from "draft-js";

import classNames from 'classnames'




export const CatDocDraftEditor = ({base, classes, tag, docId, $ , editable}) => {
    console.log(`rendering #${tag}`)
   // return (
   //     <TextareaAutosize  key={tag} onChange={e => $.change(tag, e)} aria-label="empty textarea" placeholder={`text for ${tag}`}/>
   // ) 

            
        if (!editable) {
            console.log('x')
        }        

        const [editorState, setEditorState] = React.useState(base);
        //const handleReturn = editable ? null : _ => 
        React.useEffect(() => {
            if (editorState !== base) {
                setEditorState(base)
            }
        }, [base])

        const onChange = (s:EditorState) => {
            
            // -- we want to use onChange to hold on to the selection information, but disallowing content changes
            if (!editable) {
                const content = s.getCurrentContent()
                if (content !== base.getCurrentContent()) {
                    const sel = editorState.getSelection() 
                    s = EditorState.forceSelection(base, sel) // <-- revert to base content 
                }
            }

            $.change(docId, tag, s) // <-- will not trigger a re-render
            setEditorState(s)       // <-- may trigger a re-render, but draft smart enough to handle it efficiently
         
        }

        return (
            <div className={classes.container}>
            
                <div className={classNames( classes.editorContainer, {
                            [classes.editorReadOnly]: !editable,
                            //[classes.error]: props.error 
                        })}>
                    <Editor  onTab={onTab}  keyBindingFn={$.key} customStyleMap={styleMap} editorState={editorState} onChange={onChange} />
              </div>
            </div>)
    }
       

    const onTab = (e:any) => {

        e.preventDefault()
    }


    const styleMap = {
        'QUOTED': {
             backgroundColor:  "#80deea" // "#f48fb1"  // "#f06292"    //  "#bbdefb"
        }        

      };

