import { useEffect, useMemo, useState } from "react"
import { Async, AsyncUtil, Dispatcher, dsl, State, suffixed } from "../../../ps/dsl"
import firebase from "../../../firebase"

import {getSchemaP} from './eff/getSchemaP'
import {createCatDocP} from "./eff/createCatDocP"
import { ReduxActions } from "../../../model/ReduxActions"
import { useDispatch } from "react-redux"
import { CreateCatDocPM } from "./CreateCatDoc"

import {changeP, validateP, addOptionsP} from '../../../ps/form/form-ps'
import { useStart } from "../../../ps/dsl-react"

const validators = {
    docId: v => {
        return (v == null || (typeof v === 'string' &&  v.length > 0 && !v.match(/[.$\[\]#\/]\s/))) ? null 
        : "invalid key (cannot have symbols or spaces)"
     }
} 

const pm0:CreateCatDocPM = {
    formSchema: [
        { id: 'schemaId', label: 'catgeories', value: 'thesis.lea.thesis-categories', desc:"Categories for this document", type:'selector', dataProvider:'schemas' },
        { id: 'title', label: 'title', value: '' }, 
        { id: 'docId', label: 'doc name (optional)', value:'', desc:"convenient name for doc id"}
      ],
    validators,

    values: {
        schemaId:'',  
        title:''
      },
    options:{},


}


export const useCreateCatDocPs = ():{pm:CreateCatDocPM, $:any} => {

    const [pm, setPM] = useState(pm0)
    const dispatch = useDispatch()

    
    const $ = useMemo(() => {
        var $:any = {}

        const ps = {
            '<cats[]|':firebase.db.collection('cats'), 
            '<CatDoc[]|':firebase.db.collection('CatDoc') 
        }
    
        const effs = suffixed({
            startP, // <-- retrieve schemas etc

            // -- form
            changeP, addOptionsP, validateP,
            submitP
        }, {P:[ps,$]})
        $.run = dsl([
                State(pm0, setPM),  // Set, Get, Append
                Dispatcher(ReduxActions, dispatch),  // <-- effects to global redux reducer

                Async(suffixed({
                    getSchemaP,   // <-- retrieve base schemas for dropdown
                    createCatDocP,
                    ...effs
                }, {P:[ps, $]})),
                AsyncUtil 
            ])
      
        return {...$, ...effs}
    }, [])


    useStart($)


    return {pm, $}
}


const startP = (_, $) => () => $.run(function*($$) {
    // -- possibility of caching ... 
    const {ok, v:schema, err} = yield $$.getSchema() 
    if (ok) {
        yield $$.addOptions("schemaId", schema   )
    }

})



const submitP = (_, $) => evt => $.run(function*($$) {
    evt.preventDefault()


    const {ok:validates, v:values} = yield $$.validate()

    if (validates) {
        yield $$.Set({isSubmitting:true})  
        const {schemaId, title, docId} = values
        
        const {ok:docCreated, v:newDocId, err} = yield $$.createCatDoc("ian", schemaId, title, docId)


        if (docCreated) {
            yield $$.CAT_DOCS(newDocId) 
        } else {
            yield $$.Set({isSubmitting:false, err:err.message})  // <-- TODO - add erros 

        }
    }
})
