import * as React from 'react'
import { BibModel, toBibModel } from './model/ParseGenPM';
import { Ref, RefSource } from "./model/searchRefs";

export const BibPublish = (bibModel:BibModel) => {
    if (!bibModel) {
        return (<div>no reference</div>)
    }

    return (<div>
             <h1>Bibliography</h1>
             {bibModel.refItems.map(({ref, txt})  => (
                <div>
                    <div>{txt}</div> 
                    <br />
                </div>))}
            </div>)
    
}
