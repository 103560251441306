import {  useMemo, useState } from "react"
import { Async, dsl, State, suffixed } from "../dsl"
import firebase from '../../firebase'
import { FormBase } from "../form/FormBase"
import { validEmail } from "../form/validators"

// -- imported langauge --
import {loginP, signoutP} from './firebase-auth-ps'
import {changeP, validateP} from '../form/form-ps'


export type LoginFormPM = FormBase<LoginValues> 

const pm0:LoginFormPM = {

    formSchema: [
        { id: 'email', label: 'email', value: '' },
        { id: 'password', label: 'password', value: '', isPassword:true},
    ], 

    values: {
        email:'',
        password:''
    },

    validators: {
        email: validEmail
    }
}
    
    

export type LoginValues = {
    email: string
    password: string
}

export const useUserPs = ():{pm:LoginFormPM, $:any} =>  {

    const [pm, setPM] = useState(pm0)  // <-- form state
    
    const $ = useMemo(() => {
        var $:any = {}

        const ps = {
            '<auth|': firebase.auth  // <-- implementation specific
        }

        const effs = suffixed({
            // -- form algebra , build on simple state reducer (and the Set, Get, Validate algebra)
            changeP,  validateP,   

            // -- firebase algebra, build on underlying firebase process <auth|
            loginP,    signoutP, 

            // -- form submition then combines these
            submitP
        }, {P:[ps,$]})
        
        $.run = dsl([ State(pm, setPM),  Async(effs),  ])
    
        return  {...$, ...effs}
    }, [])


    return {pm, $}
}







/** 
 *  Submittions  
 *    - Form algebra (ie. validate)
 *    - Login algebra (ie. firebase implemention of login)
 *    - some addition process level state (ie Set({isSubmitting}))
 * 
 * 
 *  Note: the only way to reset (ie set isSubmitting back to false)  id 
 */

const submitP =  (ps, $) => event => $.run(function*($$) { 
    event.preventDefault();
    
    const {ok, v} = $$.validate()
    if (ok) {
        yield $$.Set({isSubmitting:true})
        const {ok:loginOk, err} = yield $$.login(v.email, v.password)  
        
        if (!loginOk) {
            yield $$.Set({err:err.message, isSubmitting: false}) // submit fails ..
        } else {
            // higher level process will listen for login success ... nothihg
        } 
        //yield $$.Set({isSubmitting:false})   // <-- not going to rest 
        
    }
})


