import * as React from 'react'
import { CatDoc, ShowCatDocsPM, useCatDocs } from './CatDocs-ps'



export const CatDocs = () => {

    const {pm, $}:{pm:ShowCatDocsPM, $:any} = useCatDocs() 

    if (!pm.docs) {
        return (<div>... {pm.status} </div>)
    } 

    return (<div> 
        <a onClick={$.reload}>reload</a>
        {pm.docs!.docs.map((doc:CatDoc) => {
            const {id, schemaId, title, user} = doc
            return (<div key={id}>
                <h2 onClick={() => $.selectDoc(id)}  >doc: {title} </h2>
                <div>user:{user}</div>
                <div>schema:{schemaId} </div>
                <div>id:{id} </div>
            </div>)
        })} 

    </div>)
}