import firebaseConfig from './config'
import app, { firestore } from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'

class Firebase {
  
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth()
    this.db = app.firestore()
    this.TIMESTAMP = () => firestore.FieldValue.serverTimestamp() 
    this.database = app.database()
  }

  async register(name, email, password) {
    const newUser = await this.auth.createUserWithEmailAndPassword(email, password)

    await newUser.user.updateProfile({
      displayName: name,
      photoURL: `/public/avatars/${name}`
    })
  }


  async login(email, password) {
    var result =  await this.auth.signInWithEmailAndPassword(email, password)
    return result 
  }

  async logout() {
    await this.auth.signOut()
  }

  async resetPassword(email) {
    await this.auth.sendPasswordResetEmail(email)
  }

}

var firebase = new Firebase()
export default firebase
