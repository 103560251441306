// const type__Actions = 'string ->    <-- this is an entire dsl, not easily typed
// const data__Handler = Reducer .actions dispatch:(any -> void) 


// -- generatable
const PROMISE = "__Promise"


export type DslDef = {
  $:'Reducer', actions:(name:string) =>  any,
  reducers: {[name:string]:((state:any, action:any) => any)},
  selectors: {[name:string]:(state:any) => any},   // extension of reducer to effect albegras
  state:any
  setState:any
} | {
  $:'Dispatch', 
  actions:(name:string) => any,
  dispatch:(action:any) => void,
  selectors?:{
    $:'Selectors'
    selectors:{[name:string]:(state:any) => any},    // TODO extact to Selector object? 
    stateRef:{current:any}
  }
} | {
  $:'Async',
  actions:{[name:string]:any}
}  
 

export const Reducer = (actions, reducers, selectors, state, setState):DslDef =>  ({$:'Reducer', actions, reducers, selectors, state, setState })
export const Dispatcher = (actions, dispatch, selectors?):DslDef => ({$:'Dispatch', actions, dispatch, selectors})
export const Async = (actions):DslDef => ({$:'Async', actions})

// -- 
export const Selectors = (selectors, stateRef) => ({$:'Selectors', selectors, stateRef})


type HandlerSpec = {
  defs:DslDef[],
  actions:{[name:string]:ActionImpl}
  $$:any  //  constructors 
}

type ActionImpl = {fn:any, isSelector?:boolean, name:string, isAsync?:boolean, def:DslDef}




export const dsl = (defs:DslDef[]) => $handle(toSpec(defs))

const $handle = (spec:HandlerSpec):(gen:any) => CPromise<any> =>  {
  const {$$, actions} = spec

  return gen => {
    var canceled = false
    var inProgress:Promise<any>|null // <-- in progress cancellable  promise
    const cancel = () => {
       canceled = true
       if (inProgress instanceof CPromise) {
         inProgress.cancel()   
       } 
    }

    var it = gen($$)
    var value
    var done = false

    const out = new CPromise((resolve, reject) => {

      const tick = v => {

        while (!done && !canceled) {
          ({ value, done } = it.next(v)); // generator supplies  f: (a -> t b)
          v = null // <-- synchronous selector may set the call value below
          var handler:ActionImpl|null = null

          if (done) {
            resolve(value)
            return
          } else {
            if (value == null) {
              assert(false, "--- 'yield null' <-- probably $$.Misspelling")
            }
            var name
            try {
              name = value ? (value.$ || value.type) : null 
              if (name) {
                if (name === PROMISE) {
                  name = value.name 
                } 
               handler = actions[name] 
              }
            } catch (e) {
              // no value for handler
            }
            if (!handler) {
              console.log('x')
            }
            assert(handler!, `   ERR: invalid dsl term `, {value})
 
           

            const {fn, def} = handler!
            if ((typeof fn != "function")) {
              throw new Error("bad value -- TODO catch this on compile ")
            }
            console.log(`ACTION: ${handler!.name}  yield `, {value})
            switch (def.$) {
              case 'Dispatch':
                if (handler!.isSelector) { 
                  v = fn(def.selectors!.stateRef!.current) 
                  console.log(`   v =  `, {v})
                } else {
                  def.dispatch(value)
                }
                break
              case 'Reducer':
                if (handler!.isSelector) {
               
                  v = fn(def.state) // <-- this will be the return value as we synchronously continue loop 
                  console.log(`   v =  `, {v})
                } else {  
                  var state = fn(def.state, value)
                  if (state === null || state === undefined) {
                    throw new Error(`null from reducer ${handler!.name}`)
                  }
                  if (state !== def.state) {  // <-- don't mutate values, will fail to perform update on view 
                    console.log(`   -> state = `, {state})
                    def.state = state     // <-- mutating values (here's where we implement the state effect
                    def.setState(state)  // <-- this is the react effect
                  }
               }
                break
              case 'Async':
                inProgress = fn(value)
                  .then(v => {
                    console.log(`   =>  v = `, {v});
                    inProgress = null
                    if (!canceled) {
                      tick(v)
                    }
                  })
                  .catch(err => {
                    inProgress = null
                    const stack = err.stack
                    console.log(' error ' + stack)
                    console.log(`   => Promise error ${handler!.name}  :: `, {err}   )
                    throw new Error(`Command failed ${handler!.name}   message: \n + ${err.message}` )
                  })
                  
                if (!(inProgress instanceof Promise)) {
                  throw new Error('function must return promise')
                } 
                return  // <-- stop iteration, as we've injected the control flow into the promise
            }
          }
        }
      } // tick
  
      tick(null)  // <-- start

    })  
    out.cancel = cancel // <-- more "stop" that cancell, really
    return out 
  }
}



//-- async utils
export const AsyncUtil = Async({
  wait: t => (new Promise((accept,_show) => { setTimeout(() => accept(null), t) })),  // <-- TODO - make cancellable
  async: fnP => fnP(),   // <-- returns a promise  // await?
  asyncR: fnP => new Promise((acc) => fnP().then(v => acc(Ok(v))).catch(e => acc(Err(e)))),  // <-- wraps promise in Result
  result: fn => {     // ie yield $$.result( (Ok, Err) => {  ... async funtionality ... return () => stop() })
        var cancel:any
        var p = new CPromise((acc, rej) => (cancel = fn( v => acc(Ok(v)), e => acc(Err(e)) )))    // ie fn( (Ok, Err) => {  ... return () => stop() })
        p.cancel = cancel || p.cancel  
        return p
      }

}) 




// -- simple wrapper for a simple state reducer
//
//  ie  const [state, setState] 
//  $.run = dsl( [State(state,setState), ... ])
// 
//  $.run(function*({Set, Put}) {
//    yield Set({p1:newValue, p2:etc})    <-- changes the p1 & p2 on the model
//    yield Put(newState)                 <-- overwrites old state
//    yield Append({messages:['another message]', errors:['another error] })  // -- append arrays 
//    var state:MyPM = yield Get  
//   }
export type ReducerDSL =  {$:'Set', vs:any } | {$:'Put', s:any } 



export const StateTerms =  { 
  Set: vs => ({$:'Set', vs}),
  Put: s => ({$:'Put', s}),
  Append: vs => ({$:'Append', vs})
}

export const StateSelect = {Get: s => s}

export const StateReducer = {
  Set: (state, {vs}) => ({...state, ...vs}),
  Put: (_, {s}) => s,
  Append: (pm, {vs}) => Object.keys(vs).reduce((pm, k) => (pm[k] = [...(pm[k] || []), ...vs[k]])  , {...pm})
}


export const State = (state, setState) => Reducer(StateTerms, StateReducer, {Get: s => s}, state,  setState)



/**
 * Utility:   
 * 
 *  suffixed({ cmdP, cmd2K, cmd3KP, cmd4PK}, {P:[ps,$], K:[k]} 
 *   
 *  const cmdP = (ps, $) => ...
 *  const cmd2L = k => ...   
 *  const cmd3KP = (ps, $) => k => ...    
 *  const cmd4PL = k => (ps, $) => ...
 * 
 */
export const suffixed = (o, s) => {
  var out = {}
  var suffixes = Object.keys(s) 

  for (var name in o) {
      var found = true
      var fn = o[name]
      var fname = name

      while (found) {
          found = false
          for (var sfx of suffixes) {
              var sub = fname.substr(fname.length - sfx.length)
              if (sub == sfx) {
                  found = true
                  fn = fn.apply(null, s[sfx])
                  fname = fname.substr(0, fname.length - sfx.length )
              }
          }
      }
      out[fname] = fn
  }
  return out

}


/**
 *  -- javascript friendly version of the Either monad
 *  ie. 
 *   const {ok, v, errors}:Results<string> = yield something
 *   
 */
export type Result<a> = { ok:true, v:a} 
  | { ok:false, err:{message:string}}

export const Ok = <a>(v:a):Result<a> => ({ok:true, v})
export const Err = <a>(err:{message:string}) => ({ok:false, err})



const toSpec = (defs:DslDef[]):HandlerSpec => {
  
  var actions:{[name:string]:ActionImpl} = {}
  
  var $$ = {}
  defs.forEach(def => {
    switch (def.$) { 

      case 'Reducer':
        Object.keys(def.actions).forEach(name => {
          $$[name] = def.actions[name]  // <-- constructor
          const fn = def.reducers[name]
          actions[name] = {fn, def , name }   
        })
        Object.keys(def.selectors).forEach(name =>  {
          $$[name] = {$:name}  // <-- extremely simple selector algebra.
          actions[name] = {fn:def.selectors[name], def, isSelector:true, name }
        })
        break
      case "Dispatch": 
        Object.keys(def.actions).forEach(name => { 
          $$[name] = def.actions[name]
          actions[name] = {fn:def.dispatch, def, name}
        })  
        if (def.selectors) {
            Object.keys(def.selectors!.selectors).forEach(name =>  {
            $$[name] = {$:name}  // <-- extremely simple selector algebra.
            actions[name] = {fn:def.selectors!.selectors[name], def, isSelector:true, name }
          })
        }

        break
      case "Async":
        Object.keys(def.actions).forEach(name => {
          
          $$[name] = (...args)  => ({$:PROMISE,  name, args}) 
          const promiseFn = def.actions[name] 
          const fn = ({args, name}) => {
            console.log(`    ${name}  => Promomise   ,  args= `, {args})
            return promiseFn.apply(null, args)
          }
          actions[name] = {fn, def, isAsync:true, name}   
        })  

        break
    }

    })
    validate(actions)

  
  return { defs,actions, $$:wrap$$($$)}
}

const validate = actions => {
  for (var name in actions) {
    assert(typeof actions[name].fn == 'function', `invalid handler ${name}`, actions[name]) 
  } 
}

const assert = (v, msg, o?) => {
  if (!v) {
    console.log(`Assertion Fail: "${msg}"`, {o, msg})  
    throw new Error(msg) 
  }
}
  

// -- debug only

const wrap$$ = $$ =>  {

  // -- could show 
  const cmds =  " --- dsl terms on $$ --- \n" + Object.keys($$).map(v => `  - ${v}`).join('\n')

  return new Proxy({}, {
    get:  (_, key, __) => {
      //console.log(`getting ${key}!`);
      if (key == "_show") {
        return () => {
          console.log(cmds)
          return cmds
        }
      }
      if ($$[key] != null) {
        return $$[key]
      }
      console.log("invalid dsl term $$." + key.toString()) 
      console.log(cmds)
      throw new Error("invalid term: $$." + (key.toString()) + '\n' + cmds)
    }

  }) 
}

// -- Cancellable promise 
class CPromise<T> extends Promise<T> {
  public cancel:() => void  =  () => undefined
} 