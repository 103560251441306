import { useState } from 'react';
import { CatsPM } from '../../../model/cats/model/CatsPM';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import * as React from 'react'

import { useCurrentCats, usePage } from '../../../model/ps/usePageLoader';
import { Async, AsyncUtil, Dispatcher, dsl, Selectors, State, suffixed } from '../../../ps/dsl';
import { ReduxActions } from '../../../model/ReduxActions';
import { createP } from './eff/createP'
import { updateP } from './eff/updateP'
import { loadCatSchemaP } from "./eff/loadCatSchemaP"
import {  pageToDocPath } from './model/schema-model';
import { PageState } from '../../../model/resource/PageResource';
import { EditorState } from 'draft-js';

import firebase from "../../../firebase"

const pm0 = {requireCreate:false, state:null}

/**
 * Builds the effect algebra for the cat schema editor
 * 
 */
export const useCatSchemaEditorPs = <vm>(rurl):{requireCreate:boolean,pm:CatsPM<vm>, $:any} => {
  const pageRef:any = React.useRef(null) 

  const page:PageState<CatsPM<vm>, any> = usePage(rurl) as any;
  pageRef.current = page
  
  const [{requireCreate}, setPm] = useState(pm0) 

  const dispatch = useDispatch()
  const currentCat = useCurrentCats()   
  const {docPath} = pageToDocPath(page)
  

  const ps = useMemo( () => ({
    '<cats[]|':firebase.db.collection('cats'),
    '<content[]|':firebase.db.collection('content1')
  }), [])


  const $ = useMemo( () => {
    var $:any = {}

    const eff = suffixed({
      createP,       // $.create  ::  (CatsPM <-)  Create page:(PageResource CatsPM)
      updateP,       // $.update  ::  (_ <-)        
      loadCatSchemaP, 
      changeP,
      
      // -- 
      startP

    }, {P:[ps, $]})

    $.run = dsl([
      State(pm0, setPm),   // ie $$.Set({requireCreate:true})  <-- sets requireCreate
      Dispatcher(ReduxActions, dispatch, Selectors({SelectPage: p => p}, pageRef)), // <-- no selector 
      Async(eff) , AsyncUtil
    ])

    return {...$, ...eff} 
  }, [])


  useEffect(() => {
    const {cancel} =  $.start(rurl, docPath, page.data as CatsPM<any>, currentCat)
    return cancel
  }, [docPath]) 


  return {requireCreate, pm:page.data!, $}

}


const startP = (ps, $) => (rurl, docPath, pm:CatsPM<EditorState>, currentCat) => $.run(function*($$) {
  if (pm) {
    if (pm != currentCat) {
      $.run(function*($$){
        $$.SET_CAT(pm) 
      })
    }
  } else {
    yield $$.loadCatSchema(rurl, docPath)
  } 

})

export const _schemaCache:any = {}



const changeP = (ps, $) => state =>  $.run(function*($$){

  const page:PageState<any,any> = yield $$.SelectPage
  const rurl = page.resource.rurl
  _schemaCache[rurl] = state


})




