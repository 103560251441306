import { createMuiTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple'
import green from '@material-ui/core/colors/green'

const arcBlue =    '#0B72B9'  // '#0097a7' //
const arcOrange =  '#f06292' // '#b2ebf2' // '#FFBA60'

const lPink = '#e7afbb'
const dPink = '#f90b6d'
const headingPink = '#e70d3d'

export const theme = createMuiTheme({
  pacing: 8,
  palette: {
    common: {
      blue: `${arcBlue}`,
      orange: `${arcOrange}`,
      lpink:  `${lPink}`, // <-- light pink
      dpink: `${dPink}`, // <-- dark pink
      headingPink: `${headingPink}`
    },
    primary: {
      main: `${arcBlue}`
    },
    secondary: {
      main: `${arcOrange}`
    }
  },
  primary: {
    main: arcBlue
  },
    // light
    // main
    // dark
  // secondary

  // error
  // text   <-- opacities etc
  // action   <-- opacities 
      // action, hover, selected etc 
 
  typography: {
    fontFamily: '"Roboto", "Helvetica"',
    fontsize: 14,
    h3: {
      fontWeight: 300
    }
  }, 

  
} as any) 