import { EditorState } from 'draft-js';
import { CatsPM } from './model/CatsPM';



/**
 *  dsl CatCmd = SetCat rurl:string pm:CatsPM
 * 
 */



export const CatActions = {

    SET_CAT: (pm:CatsPM<any>) => ({ type: "SET_CAT", pm }),
    SEL_CAT: path => ({type:"SEL_CAT",  path}),
    PRELOAD_CAT: rurl => ({type:"PRELOAD_CAT", rurl  }),
    CAT_SAVE: () => ({type:'CAT_SAVE'}),

    CAT_CHANGE:(pm:CatsPM<any>, id:string, state:EditorState, initialValue?:string) =>
         ({type:'CAT_CHANGE', pm, id, state, initialValue}),

    CAT_RENDER:(pm:CatsPM<any>, id:string, state:EditorState) =>
         ({type:'CAT_RENDER', pm, id, state})

}


// -- notify process that 

