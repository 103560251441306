
export type CatDoc = {
    id:string
    created:any
    schemaId:string
    title:string
    user:string
}

export type CatDocsPM = {
    docs:CatDoc[]
}

export const createCatDocsPM = (docs:CatDoc[]):CatDocsPM =>  {
    return {docs}
}