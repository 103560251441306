import { ContentState, convertFromRaw, EditorState } from "draft-js"
import { CatItem, toCatItems } from "../../../model/cats/model/CatItem"
import { CatSchema, TagRef } from "../../../model/cats/model/CatSchema"
import { CatDoc } from "../CatDocs/CatDoc-pm"
import { DocCache } from "./DocCache-dsl"


export type CatDocContentPM<vm> = {
    doc:CatDoc
    schema:CatSchema<TagRef>
    content:{[tagId:string]:CatDocContent<vm>}
    tagList:CatItem[]
    tagChildren:{[tag:string]:string[]} 
    top:CatItem
    tagParents:{[tag:string]:string}
    tags:string[]
} 
  
export type CatDocContent<vm> = {
    id:string  // <-- cf. path
    created:any
    updated:any

    raw:string  // <-- raw serialization 
    data?:any   // <--  
    vm:vm       // <-- view model (ie draft-js)

}





export const createCatDocContentPM = (doc:CatDoc, schema:CatSchema<TagRef>, content:any, cache:DocCache ):CatDocContentPM<any> => {

    const tagList:CatItem[] = toCatItems('temp/', schema)
    const tags = tagList.map( ({tag}) => tag)
    
    // FIX_THIS - hack 
    tagList[0].children = undefined 


    var tagChildren:{[tag:string]:string[]} = {}
    var tagParents:{[tag:string]:string} = {}
    tagList.forEach((item:CatItem) => {
        if (item.children && item.children.length > 0)  {
            tagChildren[item.tag] = item.children
            for (var childTag of item.children) {
                tagParents[childTag] = item.tag;
            }

        }
    })
    var top:CatItem = tagList.shift()!  // <-- don't render this hierarchically


    const docPm:CatDocContentPM<any> = {
        doc,      
        schema, 
        //byTag,
        content,
        tagList,
        tagChildren,
        tagParents,
        top,
        tags
    }

    for (var tag of tags) {
        var retrieved = content[tag]
        var o:ContentState

        if (retrieved) {
            const {raw} = retrieved
            try {
                var rawo = JSON.parse(raw)
                o = convertFromRaw(rawo)
                var editorState = EditorState.createWithContent(o)

            } catch (e) {
                console.log('cannot parse  ', {e})
                throw "Cannot deserialize server content"
            }
            //cache.setBaseContent(...) 
            //cache.setContent(doc.id, tag, null as any)
            //baseContent[tag] = editorState
            cache.setBaseContent(doc.id, tag, editorState, raw)
            cache.setContent(doc.id, tag, null)
        } else {
            //baseContent[tag] = EMPTY
            cache.setContent(doc.id, tag, null as any)

        }


        //cache.setBaseContent(doc.id, tag, content.vm)   
    }



    cache.setCachedDoc(doc.id, docPm)

    return docPm
}