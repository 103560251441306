export type RefSearchResults = {
    ref:any
    searchValue:string
    hits:any
}

export type RefSource = {
    uid:string
    title:string    
    ignore?:boolean
    data:Ref[]
}

export type Ref = {
    uid:number
    docName:string
    ps:{[name:string]:string}
}

export type Hits = {
    refs:RefSource[]
    searchValue:string
    hasHits,
    hitsBySection:{[uid:string]:boolean}
    hits:{[uid:string]:boolean}
}



export const updateSearchResults = (searchValue, prevResults, refs):Hits => {

    var hitsBySection:{[uid:string]:boolean} = {}
    var hits:{[uid:string]:boolean} = {}
    var hasHits = false
    refs.forEach(({uid, data}) => data.forEach((ref:Ref) => {
            if (ref.docName.includes(searchValue)) {
                hasHits = true
                hitsBySection[uid] = true
                hits[ref.uid] = true
            }
        }))

    return {
        refs,
        searchValue,
        hasHits,
        hits,
        hitsBySection
    }

}




