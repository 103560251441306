import { useDispatch, useSelector } from "react-redux"
import firebase from "../../firebase"

import {useEffect, useMemo}  from 'react'
import { FBUser } from "./User-dsl"
import { selectUser } from "../../model/selectors"
import { Async, AsyncUtil, Dispatcher, dsl, suffixed } from "../dsl"
import { UserActions } from "../../model/userReducer"
import { UserState } from "../../model/user/UserState"
import { NavActions } from "../../model/pageReducer"



export const useCurrentUserPs = ():UserState => {

    // -- user from 
    const pm:UserState = useSelector(selectUser) 
    const dispatch = useDispatch()

    
    
    const $:any = useMemo(()=>{
        const $:any = {
            ps: {
                '<auth|': firebase.auth    // <-- don't really need this construct 
            } 
        }

        const effs = suffixed({
            startP
        }, {P:[$.ps, $]})

        $.run = dsl([ 

                Async(effs),
                Dispatcher({...UserActions, ...NavActions}, dispatch),
                AsyncUtil
            ])
        return {...$, ...effs}

    },[])

    useEffect(() => {
        const p = $.start()
        return () => p.cancel()
    },[])


    return pm
}

const startP = (ps, $) =>  () => $.run(function*($$) {
    // -- this keeps running until it's cancelled
    yield $$.result((_,__) => {   
        var unsubscribe = ps['<auth|']
            .onAuthStateChanged(user => $.run(function*($$) {
                if (user) {
                    const usr:FBUser = toFBUser(user)
                    yield $$.SET_USER(usr)       // <-- possible that the navigation is causing the user to log out
                    yield $$.HOME()              // <--
                } else {
                    //console.log('-- no user --')
                    yield $$.LOGIN 
                    yield $$.CLEAR_USER()  
                }
            }))
        return () => {
            unsubscribe()  
        }
    })
})



export const toFBUser = (user:firebase.User):FBUser =>  
    FBUser( user.uid,  user.displayName!, user.photoURL!)



