import { convertToRaw } from "draft-js"
import firebase from "../../../../firebase"
import { DocCache } from "../DocCache-dsl"



// -- this obviously doesn't need to be implemented here 
export const saveP = (ps, $) => () => $.run(function*($$) {

    yield $$.SET_SAVE_IN_PROGRESS( 'yes' )

    const cache:DocCache = yield $$.DocCache
    const tags = cache.allIds(true)

    const unsaved = cache.allUnsaved() 
    const oks:string[] = []
    const errs:any[] = []
    for (var item of unsaved) {
        const {docId, tag, content} = item
        console.log(`saveing: <CatDoc.${docId}.content[].${tag}`)
        // -- serialize 
        
        const doc = content.getCurrentContent()
        var raw = JSON.stringify(convertToRaw(doc))
    

        const {ok, err} = yield $$.result( (Ok, Err) => { 
            // CatDoc/:id/content/:tag
            ps['<CatDoc[]|'].doc(docId).collection("content").doc(tag)
                .set({
                    raw,
                    updated:firebase.TIMESTAMP() 
                })  
                .then(v => {
                    // -- cache this as the saved doc
                    cache.setBaseContent(docId, tag, content, raw)
                    Ok()
                })
                .catch(e => {
                    Err({e, tag})
                })
            })
            if (ok) {
                oks.push(tag)
            } else {
                errs.push({tag, err})
            }


        //const {docId, tag, content} = {}

       // yield $.result( (Ok, Err) => {


       // })

    }
    const stillUnsaved = cache.allUnsaved()  // <-- could be asynchonous 
    const ok = (oks.length == unsaved.length);

    if (ok) {
        yield $$.SET_SAVE_IN_PROGRESS( 'ok' )
        if (stillUnsaved.length == 0) {
            yield $$.wait(1000)
            yield $$.SET_SAVE_IN_PROGRESS( "no" )
        }
        yield $$.SET_REQ_SAVE(false)
    } else {
        yield $$.SET_SAVE_IN_PROGRESS("err")
    }


})