import { Box, CircularProgress, Paper } from '@material-ui/core'
import * as React from 'react'
import {useMemo, useState} from 'react'
import { CatDocDraftEditor } from '../../Categories/CatDocsContent/CatDocDraftEditor'
import { useEditorStyles } from '../../Categories/CatDocsContent/CatDocTest-styles'
import { AntTab, AntTabs, TabPanel } from './TabHelpers'
import { useBibliographyPs, MODES, BibViewPM } from './Bibliography-ps'
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import SearchBar from "material-ui-search-bar";
import { BibSearchResults } from './BibSearchResults'
import { BibPublish } from './BibPublish'
// *snip*

 
// https://github.com/TeamWertarbyte/material-ui-search-bar

export const Bibliography = () =>   {

    const classes = useEditorStyles()

    const {pm, $} = useBibliographyPs()

    // -- cache the editor, so we don't need to re-render for small changes
    const editor = useMemo(() =>  {
        if (pm.docPm) {
            const doc = pm.cache!.getDoc(pm.docId!, TAG)
            return  <CatDocDraftEditor editable={true} classes={classes} base={doc} tag={TAG} docId={pm.docId} $={$}/> 
        } 
        return null
    }, [pm.docId, pm.docPm] )


    if (pm.status !== 'ready') {
        return inProgress(pm.status)
    } 
    const {mode} = pm 
    const isSearch = (mode === MODES.SEARCH)
        return (<Grid container  style={{paddingTop:20}} >
            <Grid  item  sm={12} lg={6} style={{minHeight:50, zIndex:10000}} >

                <Box position="fixed" >     
                <Paper>
                    <AntTabs  value={mode} onChange={$.setMode}>
                        <AntTab label="edit" />
                        <AntTab label="search"  />
                        <AntTab label="publish" />
                    </AntTabs> 
                    </Paper>
                </Box>
            </Grid>
                {isSearch && 
                <Grid  item  sm={12} lg={6}>
                 <SearchBar
                    value={pm.searchValue}
                    onChange={$.setSearch}
                    onRequestSearch={$.searchRequest}
                />
            </Grid>}
            <Grid   style={{paddingTop:30}} item xs={12}>

 
            <TabPanel value={mode} index={0}>
                {editor}
            </TabPanel>
            <TabPanel value={mode} index={1}>
                {pm.searchResults ?
                    <SearchResults pm={pm} $={$}/>
                    : "pending"}

            </TabPanel>
            <TabPanel value={mode} index={2}>
                 {BibPublish(pm.bibModel!)}
            </TabPanel>
            </Grid>
         </Grid>

        )
}


const SearchResults = ({pm, $}:{pm:BibViewPM, $:any})  => {
    const {bibModel} = pm
    const searchResults = useMemo(() => (<BibSearchResults pm={pm} $={$}/>)
        , [pm.searchResults])

    if (!bibModel) {
        return  <Alert severity="error">No data yet </Alert>

    }
    const {parseErr, refs} = bibModel

    if (parseErr) {
        return ( <Alert severity="error">Cannot parse bibliography .. err {`"${parseErr}"`} </Alert>)
    }
    return <div>    
        {searchResults}
    </div>


}






const TAG = "_"  // <-- hard code that bibliographical data is stored in the top level tag

const inProgress = status => (<div>
    <h2>  <CircularProgress />  {status} </h2>
</div>)

