import * as React from "react" 
import { useUserPs } from "../../ps/user/user-ps";
import { BaseForm } from "./BaseForm";



export const Login =  () =>  {
    const {pm, $} = useUserPs() 
    return BaseForm(pm, $)
}
  
  
  