import { PageState } from './../../resource/PageResource';

export type ContentWrapper<a> = {
  rurl:string 
  content:a
  children?:{[id:string]:ContentWrapper<a>}
  created:any
//  postedBy: {
//    id:string
//    name:string
//  }
}

export const createSchemaContent = <a>(user, page:PageState<any,any>, content:a, children, raw):ContentWrapper<a> => {


  const newContent = {
    rurl: page.resource.rurl,
    content,   
    children,  // <-- child resources
  //  postedBy: {
  //    id: user.uid,
  //    name: user.displayName,
  //  },
    raw,
    created: Date.now(),  
  };

  return newContent 

}