

//const data__UserState = `UserState user:FBUser isLoading:boolean`

import { FBUser } from "../ps/auth/User-dsl";



const userState0:UserState = {
  user: undefined,
  isLoading: false
};

export type UserState = {
  user?:FBUser// <-- firebase type
  isLoading:boolean
}


export const userReducer = (state:UserState = userState0, action):UserState => {
  switch (action.type) {
    case 'SET_USER':
      return {
        user: action.user,
        isLoading: false
      };
    case 'CLEAR_USER':
      return {
        user: undefined,   // <-- larger issue
        isLoading: false
      }
    default:
      return state;
  }
};


export const UserActions = {
    SET_USER: (user:FBUser) => ({type:'SET_USER', user}),
    CLEAR_USER: () => ({type:'CLEAR_USER'})
}