import * as React from 'react'
import {Fragment} from 'react'
import { CatItem } from '../../../model/cats/model/CatItem'
import { useCatDocsTestPs } from './CatDocsTest-ps';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';    // "v" icon  
import ChevronRightIcon from '@material-ui/icons/ChevronRight';  // ">" icon
import IconButton from '@material-ui/core/IconButton';

import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

import Grid from '@material-ui/core/Grid';
import { CatDocDraftEditor } from './CatDocDraftEditor';
import { UIState } from '../../../model/ui/ui-pmodel';
import { useUI } from '../../../model/ui/useUI';
import { EditorState } from 'draft-js';
import { CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { DocSelectPM } from './DocSelectPM';


export const CatDocsTest = () => {
    const {pm, classes, $} = useCatDocsTestPs()
    // -- 
    const ui:UIState = useUI()
    return React.useMemo(() => {
        if (pm.status !== 'ready'){ 
            return  inProgress(pm.status) 
        }
        return render(pm, classes, $, ui.showBaseDoc)
    }, [pm.docPm, pm.isOpen, ui.showBaseDoc, pm.status])    // <-- note: that this protects from rerendering upon content change 
                                                 //     which is an optimization effected via the (non-functional) DocCache mechanism
} // CatDocsTest

const inProgress = status => (<div>
        <h2>  <CircularProgress />  {status}    </h2>
    </div>)

const render = (pm:DocSelectPM, classes, $, showBase) => {

    const docId = pm.docPm!.doc.id
    const tag = "_"
    const descDoc = pm.cache!.getDoc(docId, tag)

    return (<div>
            <div className={"ldocTitle0"}>{pm.docPm!.doc.title}</div>
            <Grid container spacing={0}>
                <Grid xs={12} key={`${tag}--0`}>
                    <CatDocDraftEditor editable={true} classes={classes} base={descDoc} tag={tag} docId={docId} $={$}/> 
                </Grid>
                {renderTags(pm, classes, $, showBase) }    
            </Grid>
        </div>) 
   


}

const renderTags = (pm:DocSelectPM, classes, $, showBase) => {

//(baseContent:any, cache:DocCache, schema:CatSchema<TagRef>, tags:string[], docId:string, isOpen:any, $) => {

    const { cache,  isOpen, folderOpen, docPm, baseDocId} = pm
    const {tagList, top, tagParents} = docPm!
    const {id} = docPm!.doc

    return tagList.map((item:CatItem) => {
        const {tag, title, children} = item

        if (tag == "intro") {
            console.log('x')
        }

        const isFolder = (children != null)
        const open = isFolder ? folderOpen[tag] : isOpen[tag] 


        var parent = tagParents[tag]
        if (parent && !folderOpen[parent]) {
            return null
        } 

        var docState:EditorState 
        var baseState:EditorState  
        if (open) {
            docState = cache!.getDoc(id, tag)
            if (showBase && baseDocId) {
                baseState = cache!.getDoc(baseDocId, tag)
            }
        }

    

        return (
        <Fragment> 
        <Grid item xs={12} key ={tag}>
           <div className={isFolder ? "ldocTitle1" : "ldocTitle2"}>
           <IconButton  onClick={() => $.toggleOpen(tag, isFolder)} size="small" color={isFolder ? "primary" : "secondary"} >
             { open ? <ExpandMoreIcon /> : <ChevronRightIcon  />}
            </IconButton>  

                {title} {" ("}
                <IconButton  color="primary" onClick ={() => $.setOpen("*")} aria-label="all" className={classes.margin} size="small">
                    <AddIcon fontSize="inherit" />
                </IconButton>
              
                <IconButton color="primary" onClick ={e => $.setOpen("",  tag, isFolder, e)} aria-label="none" className={classes.margin} size="small">
                    <RemoveIcon fontSize="inherit" />
                </IconButton>

                <IconButton  color="primary" onClick ={() => $.setOpen("**")} aria-label="none" className={classes.margin} size="small">
                    <AssignmentIcon fontSize="inherit" />
                </IconButton>
                
                <IconButton  color="primary" onClick ={$.toggleShowBase} aria-label="none" className={classes.margin} size="small">

                    {showBase ? <ViewHeadlineIcon />:  <ImportContactsIcon/>   }
             </IconButton>

                {" )"}
                {(open && !isFolder) && <hr className={isFolder ? "solid" : "dotted"}/>}

            </div>

        </Grid>
        
        {open && !isFolder && (
        <Fragment>

            {baseState! && 
            <Grid xs={showBase ? 6 : 12} key={`${tag}--2`}>
                <CatDocDraftEditor editable={false} classes={classes} base={baseState!} tag={tag} docId={baseDocId} $={$}/> 
            </Grid>}

            
            <Grid xs={showBase ? 6 : 12} key={`${tag}--3`}>
                <CatDocDraftEditor editable={true} classes={classes} base={docState!} tag={tag} docId={id}  $={$}/> 
            </Grid> 

            </Fragment>)} 
        </Fragment>)
    })





}



