import { EditorState } from "draft-js"
import { CatsPM } from "../../../../model/cats/model/CatsPM"
import { createViewModel } from "../model/createViewModel"
import { docToCatPM } from "../model/schema-model"






export const loadCatSchemaP = (ps, $) =>  (rurl:string, docPath:string)  =>
    ps['<cats[]|'].doc(docPath)   // <-- not sure if this is a valid key
      .get()
      .then( doc => $.run(function*($$) {
        if (doc.exists) {
          const data = doc.data()
          const pm = docToCatPM(data, rurl, createViewModel) 
          yield $$.Set({requireCreate:false})
          yield $$.SET_CAT(pm)
      } else {
        yield $$.Set({requireCreate:true}) // <-- require create
      }
    }))

    
    
