import * as React from "react"
import {useMemo} from "react"
import { Ref, RefSource } from "./model/searchRefs";


export const BibSearchResults = ({pm, $} ) => {
    const {searchResults} = pm

    const {hits, hitsBySection, refs } = searchResults 

    const byRef = useMemo(() => {
        var out = {}
        searchResults!.refs.forEach(({data} ) => {
            data.forEach((ref:Ref) => {
                out[ref.uid] = RefView(ref)
            })
        })
        return out

    }, [refs])  // <-- cache on the refernces this seach is base on
 
    
    return (<div>

             {refs.map( ({uid, data, title}:RefSource) => (hitsBySection[uid] && (<div>
                <h1>{title}</h1>
                <div>{data.map( ({uid}:Ref) => hits[uid] && byRef[uid] )} </div> 

                </div>)))}

        </div>)
        

} 



const RefView = (ref:Ref) => {
   
    return (<div>
        <h2>{ref.docName } </h2>
        <div style={{paddingLeft:20}}>{` "${ref.ps["title"] || '??'}"`} </div>
    </div>)
}