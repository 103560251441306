import { Err, Ok } from "../../../../ps/dsl";
import { createCatDocContentPM } from "../CatDocContent-model";
import { DocCache } from "../DocCache-dsl";

export const loadAndCacheCatDocP = (ps, $) => (id) => $.run(function*($$) {
    
    const cache:DocCache = yield $$.DocCache

    const {ok, v:doc, err} = yield $$.getCatDoc(id);  // <-- basic doc 

    
    if (!ok) {
        yield $$.Set({error:'not failed to load',  status:'load failed'})
        return Err({message:"failed to Load"})
    } 
    
    yield $$.Set({status:'schema loading'})  // <-- set doc 

    // -- 2. retreive schema 
    const {ok:schemaOk, v:schema, err:schemaErr} = yield $$.loadSimpleSchema(doc.schemaId)

    if (!schemaOk) {
        yield $$.Set({status:'schema failed'})
        return Err({message:"schema failed"})
    }


    // -- 3. retrieve all to document status 
    const {ok:contentOk, v:content, err:err2} = yield $$.loadCatDocContent(id)
    
    if (!contentOk) {
        yield  $$.set({status:'content error'})
        return  Err({message:"contentError"})
    }

    // -- create doc model
    const docPm = createCatDocContentPM(doc, schema, content, cache)

    return Ok(docPm)

})  