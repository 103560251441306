import { makeStyles, createStyles , Theme} from '@material-ui/core/styles';


export const editorStyles = ({ spacing, typography, palette }: Theme) => createStyles({
    root: {
    },
    container: {
        margin: spacing(1, 0, 0, 0),
        position: "relative",
        fontFamily: typography.body1.fontFamily,
        fontSize: typography.body1.fontSize,
        '& figure': {
            margin: 0
        }
      
    },
    inheritFontSize: {
        fontSize: "inherit"
    },
    editor: {
    },
    editorContainer: {
        margin: spacing(1, 0, 0, 1),
        cursor: "text",
        width: "100%",
        padding: spacing(0, 0, 1, 1),
     
    },
    editorReadOnly: {
        borderBottom: "none"
    },
    error: {
        borderBottom: "2px solid red"
    },
    hidePlaceholder: {
        display: "none"
    },
    placeHolder: {
        color: palette.grey[600],
        position: "absolute",
        outline: "none"
    },
    linkPopover: {
        padding: spacing(2, 2, 2, 2)
    },
    linkTextField: {
        width: "100%"
    },
    anchorLink: {
    },
    toolbar: {
    },
    inlineToolbar: {
        maxWidth: "180px",
        position: "absolute",
        padding: "5px",
        zIndex: 10
    }
})

export const useEditorStyles = makeStyles(editorStyles)
