import { useMemo, useState } from 'react';
import { Async, dsl, State, suffixed } from '../dsl';
import firebase from '../../firebase'

import {changeP, validateP} from '../form/form-ps'
import {registerUserP} from '../user/firebase-auth-ps'

import {saveUserP} from './firebase-user-ps'
import { validEmail, validFirebasePassword, validPasswordConfirm, validUsername } from '../form/validators';
import { FormBase } from '../form/FormBase';


export type RegisterFormPM = FormBase<RegisterValues>


export type RegisterValues = {
  name: string,
  email: string,
  password:string,
  confirm: string
}




const pm0:RegisterFormPM = {
  formSchema: [
    { id: 'name', label: 'username', value: '' },
    { id: 'email', label: 'email', value: '' },
    { id: 'password', label: 'password', value: '' , isPassword:true},
    { id: 'confirm', label: 'confirm password', value: '' , isPassword:true}
  ],
   
  values: {
    name:'',
    email:'',
    password:'',
    confirm:''
  },
  validators: {
    name: validUsername,
    email: validEmail,
    password: validFirebasePassword,
    confirm: validPasswordConfirm
  } 
  

}

export const useRegisterPs = ():{pm:RegisterFormPM, $:any} => {


    const [pm, setPM] = useState(pm0)

    
    const ps = useMemo(() => ({
        usersRef: firebase.database.ref("users")
    }), [])

    const $ = useMemo(() => {
        var $:any = {}

        var effects = suffixed({       // <-- public events $.submit & .change
          // -- form
          changeP,  validateP,
          
          // --firebase
          registerUserP,  saveUserP,
          
          // -- submit mixes the two
          submitP
        },{P:[ps,$]})

        $.run = dsl([ State(pm, setPM),  Async(effects) ])
    
        return {...$, ...effects}
    }, [])

    return {pm, $}


}

// -- form effects 





const submitP = (ps, $) => event => $.run(function*($$) {
  //const {validate, Set,  Get, createUser, saveUser} = $$
    event.preventDefault();

    const {ok, values} = yield $$.validate() // <-- validation is effectful, altering error state etc
    
    if (ok) {
        yield $$.Set({isSumbitting:true}) 
        const pm:FormBase<any> = yield $$.Get
        const {email, password, username}= pm.values
        
        const result = yield $$.registerUser(email, password, username)
        
        if (6 + 44 != 2) {
          throw "FIX_THIS - finish implementation"
        }
        if (result.ok) {
            // -- and then what to do about  // <-- 
            yield $$.saveUser("a","a","a")
            // -- 

        }  

        return result
    }
})







