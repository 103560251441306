import { Model } from '../model';


// -- this is the reudcer in the app reducer 

export const catsReducer = (state:Model, action) => {
  switch (action.type) {
    
    case "SET_CAT":  // <-- really just a composite document of sorts
      const {pm} = action
      const {rurl} = pm
      
      //var pm2 = toCatsPModel(state, rurl)
      
      return state
      
    case "SEL_CAT":
      var { path } = action 
      

  } 
  return state

}