import React from 'react';
import clsx from 'clsx';
import {  useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CatList } from '../../model/cats/CatList';
import { BubbleDrawer } from './BubbleDrawer';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import BookIcon from '@material-ui/icons/Book';

import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { useCurrentPage, usePage } from "../../model/ps/usePageLoader";
import { PageState } from "../../model/resource/PageResource";
import firebase from "../../firebase";
import { renderPage } from '../../components/routing/renderPage';
import { useUI } from '../../model/ui/useUI';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fab, Menu, MenuItem } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import Link from 'redux-first-router-link';
import SaveIcon from '@material-ui/icons/Save'
import CheckIcon from '@material-ui/icons/Check';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useAppUi } from './app-ui-ps';
import CircularProgress from '@material-ui/core/CircularProgress';


import MenuBookIcon from '@material-ui/icons/MenuBook';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { useAppStyles } from './App-styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { selectUser } from '../../model/selectors';


export const  MiniDrawer = () => {

  const {user, isLoading} = useSelector(selectUser)

  const dispatch = useDispatch()
  const classes = useAppStyles();
  const theme = useTheme();

  //const uiState = useUI()
  //const {menuOpen:open} = uiState

  const {pm, $} = useAppUi()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: (pm.saveInProgress == "ok")
  });

  const page: PageState<any, any> = usePage(useCurrentPage())
  var o = { user, firebase };
  
  const open = pm.menuOpen
  
  

  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={$.toggleDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          {open &&  <IconButton onClick={$.toggleDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          }
          <Link to="/">
            <HomeIcon />
          </Link> 


          <Link to="/bibliography/lea/thesis">
            <BookIcon/>
          </Link>

          <Link to="/project/lea/thesis">
            <MenuBookIcon />
          </Link> <div>{" "}</div>
          <Box m={2}>
            <Typography variant="h6" noWrap>
              Lea's Thesis
            </Typography>
          </Box>
          <Box m={2}>

            <Fab color="primary" aria-label="add" size="small" onClick={$.toggleShowBase}> 
              {pm.showBaseDoc ? <ViewHeadlineIcon/>:  <ImportContactsIcon/>   }
            </Fab>

            {pm.canQuote &&
            <Fab color="secondary" aria-label="add" size="small" onClick={() => $.quote(pm.canQuote)}> 
                <FormatQuoteIcon />
            </Fab>}
          </Box>

        {(pm.requireSave || pm.saveInProgress != "no") && 
          <div className={classes.wrapper}>

          <Fab  style={{
              borderRadius: 20,
              backgroundColor: "#ec407a",
              padding: "8x 8px",
              fontSize: "10px"
          }}
            size="small"
            aria-label="save"
            color="primary"
            className={buttonClassname}
            onClick={$.save}
          >
          {saveIcon(pm.saveInProgress)}
        
          </Fab>  
        
          {pm.saveInProgress == "yes" && <CircularProgress size={48 } className={classes.fabProgress} />}

        
        </div>}

      
    
     
        </Toolbar>



      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={$.toggleDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        
        <CatList open={open}/>


      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box className="route-container" width={ pm.drawerOpen ? 0.48 : 1}>  
          {renderPage(page, user)}
        </Box>
        <BubbleDrawer />

      </main>
    </div>
  );
}



const saveIcon = inProgress => {
  switch (inProgress) {
    case "yes":
    case "no":
     return <SaveIcon fontSize="small"/>
    case "ok":
      return <CheckIcon fontSize="small" /> 
    case "err":
    default:
      return <ErrorOutlineIcon fontSize="small" />
  
  }

}


  
