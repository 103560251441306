import assert from "../../../model/util/assert"

export type DocSelect = {
    isOpen:{[name:string]:boolean} 
} 


export const DocSelectTerms = {
    SetOpenV: (id,v) => ({$:'SetOpenV', id, v}),
    SetOpen: id => ({$:'SetOpen', id}),
    SetClosed: id => ({$:'SetClosed', id}),
    ToggleOpen: id => ({$:'ToggleOpen', id})
}

export const DocSelectReducer = {
    SetOpenV: (pm, {id,v}) => setV(pm, id, v),
    SetOpen: (pm, {id}) => setV(pm, id, true  ),
    SetClosed: (pm, {id}) => setV(pm, id, false ),
    ToggleOpen: (pm, {id}) => setV(pm, id, !pm.isOpen[id] )
}

export const setV = (pm, id, v) => {
    const isOpen = {...pm.isOpen, [id]:v} 
    return {...pm, isOpen}  
} // setV


export const selToString = (tags:string[], isOpen:{[id:string]:boolean}):string => {

    var t1:number = -1
    var count:number = 0
    var out = ""
    var total = 0
    const last = tags.length - 1
    var prev = false


    for (var i = 0; i < tags.length; i++) {
        const tag = tags[i]
        var open = isOpen[tag]
        if (open) {
            total++
            if (t1 >= 0) {
                count++   // <-- tag1.tag2 
            } else {
                t1 = i
                count = 0
            } 
        }  

        if ((i == last) && open) {
            open = false   // <-- forces commit             
        }

        if (!open) {
            // not open
            if (t1 >= 0) {   // <-- commit prevous tags
                out += prev ? "." : ""
                if (count > 1) {
                    out += `${tags[t1]}..${tags[t1+count]}`
                } else {
                    out += `${tags[t1]}${((count > 0) ? `.${tags[t1+1]}` : '')}`
                }
                prev = true
            }
            count = 0
            t1 = -1
          
        }

    }
    return total == tags.length ? "*" : out
}

export const selFromString = (str:string, tags:string[]):{[id:string]:boolean}  => {
    var out:any = {}
    
    if (str == "*") {
        tags.forEach(k => (out[k] = true))
    } else if (!str || str == '') {
        // -- no action
    } else {
        //                                                       "a.b..c.e"
        const doubleSplit = str.split('..')                   // [["a.b"],["c.e"]]
        const items = doubleSplit.map(tags => tags.split('.'))// [["a","b"], ["c","e"]]
 

        for (var i = 0; i < items.length; i++) {
            var tagList = items[i]
            const lastList = (i === items.length - 1)
            for (var j = 0; j < tagList.length; j++) {
                const tag = tagList[j]
                out[tag] = true
                const isLastTag = (j === tagList.length -1)
                if (isLastTag && !lastList) {
                    const i0 = tags.indexOf(tag)
                    const nextTags = items[i+1]
                    const nextTag = nextTags[0] 
                    const i1 = tags.indexOf(nextTag)
                    if (i1 > i0 && i0 >= 0 && i1 < tags.length ) {
                        for (var k = i0; k <= i1; k++) {
                            out[tags[k]] = true
                        }
                    }
                }

            }

        }
        
    }


    return out
}







export const testSelectURLSerialize = () => {

    const tags = ["a", "b","c", "d", "e"]
    const opena = {a:true}
    const openab = {a:true, b:true}
    const openabc = {a:true, b:true, c:true}
    const openabcd = {a:true, b:true, c:true, d:true}
    const openabcde  = {a:true, b:true, c:true, d:true, e:true}

    const assrt = (v, expected, vs) => {
        if (v !== expected) {
            throw new Error('unmatched')
        }

        /// this assumes that only "true keys are present"
        var open = selFromString(v, tags) 
        var expectedKeys = Object.keys(vs)
        var foundKeys = Object.keys(open)

        if (expectedKeys.length !== foundKeys.length) {
            throw new Error("bad key generation") // <-- TODO - find what's missing 
        }
        expectedKeys.forEach(k => {
            if (vs[k] !== open[k]) {
                throw new Error(`key ${k} not matched   in restorings ${v}` )
            }
        })

    
    }

    const none = {}

    str = selToString(tags, none)
    assrt(str, '', none)


    str = selToString(tags, openab)
    assrt(str, "a.b", openab )
    var str = selToString(tags, opena)
    assrt(str, "a", opena)
    
    
 
    str = selToString(tags, openabc)
    assrt(str, "a..c", openabc)
    str = selToString(tags, openabcd)
    assrt(str, "a..d", openabcd)
    str = selToString(tags, openabcde)
    assrt(str, "*", openabcde)


    const openac = {a:true, c:true}
    const openabd = {a:true, b:true,d:true}
    const openacd = {a:true, c:true, d:true}
    const openace= {a:true, c:true, e:true}
    const openacde = {a:true,c:true, d:true, e:true}

    str = selToString(tags,openacde)
    assrt(str,'a.c..e', openacde)

    str = selToString(tags,openac )
    assrt(str, 'a.c',openac)
    str = selToString(tags,openabd )
    assrt(str, 'a.b.d', openabd)
    str = selToString(tags,openacd )
    assrt(str, 'a.c.d', openacd)
    str = selToString(tags,openace )
    assrt(str,'a.c.e',openace )

    const openb = {b:true}
    const openbc = {b:true, c:true}
    const openbcd = {b:true, c:true, d:true}
    const openbcde  = {b:true, c:true, d:true, e:true}

    str = selToString(tags, openb )
    assrt(str,'b', openb )
    str = selToString(tags,openbc )
    assrt(str, 'b.c', openbc)
    str = selToString(tags,openbcd )
    assrt(str, 'b..d', openbcd)
    str = selToString(tags,openbcde)
    assrt(str, 'b..e', openbcde)


}