import * as React  from 'react'


import { useCurrentPage } from '../../../model/ps/usePageLoader'
import { MUIRichTextEditor } from '../../../muiEditor/MUIRichTextEditor'
import { FBUser } from '../../../ps/auth/User-dsl'
import { useCatSchemaEditorPs } from './CatSchemaEditor-ps'

//import { andDoCreate, andDoSerialize, useCatSchema } from './cat-schema-ps'


export const EditCatSchema = ({user}:{user:FBUser}) => {
  
  const rurl = useCurrentPage()

  const {requireCreate, pm, $} = useCatSchemaEditorPs(rurl)


  if (!pm)  {
    if (!requireCreate) {
      return <h2>Loading...</h2>
    } else return <div>
        Asset {rurl} does not exist <a onClick={$.create}>create</a>
    </div>
  }

  console.log({pm})

  const state:any = pm.state!

  return (
    <div> 
        <h1>Define Categories here</h1>
        <MUIRichTextEditor defaultValue={state} onChange={$.change} onSave={$.update} label={"some text"}/>
    </div>  
  )
}
