import { convertToRaw, EditorState } from "draft-js"
import { CatModel } from "../../../../model/cats/model/CatModel"
import { draftToSchemaDoc, updateSchemaChildren } from "../../../../model/cats/model/CatSchema"
import { CatsPM, createCatPM } from "../../../../model/cats/model/CatsPM"
import { PageState } from "../../../../model/resource/PageResource"
import { createWithDecorators } from "../../../../muiEditor/decorators"
import { createTagPM, pageToDocPath } from "../model/schema-model"

/**
 * Serialize and update 
 * 
 * 
 */
export const updateP = (ps, $) => state => $.run(function*($$)  {  //<vm>(docPath:string, docName, state_:EditorState, rurl:string, pm:CatsPM<vm>, page:PageState<CatsPM<vm>, any> ) => {
  
    //const state = _cache[rurl]
    var raw = JSON.stringify(convertToRaw(state.getCurrentContent()))
    

    const page:PageState<any,any> = yield $$.SelectPage 

    const pm = page.data
    const docPath = pageToDocPath(page)
    const docName = page.resource.params.id
    // -- serialize document to schema
    const schema = draftToSchemaDoc(state, docName) 
    // FIX_THIS - this fundamentally misunderstands how firestore works
    const {children, schema1, changed, added} = updateSchemaChildren(schema, pm.children || {})

    const content = JSON.stringify(schema1) 
    var changes:any = {content}

    /*
      TODO -  move the children to another form of document
 
    if (changed) {
      changes.children = {}
      for (var newTag in added) {
        if (!pm.children[newTag]) { // <-- if not commmitted 
          changes.children[newTag] = added[newTag]
        }
      }

      //changes.children = children  //  TODO <-- only persist new docs
    } */


    if (raw !== pm.model.raw) { 
      changes.raw = raw
    }

    
    // -- persist -- 

    yield $$.result( (Ok, Err) => {
      console.log('x')
      const ref = ps['<cats[]|'].doc(docPath.docPath)
        .update(changes).then(() => $.run(function*($$) { 
      
            
          // Here's a really good reason why this shouldnt be done in a view effect
          //  -- if the view component is unmounted before the asynchronous process completes,
          //     the pmodel becomes out of sync.

          if (17 == 41 + 34) {
            const newTagPMs =  createTagPM(children, raw => createWithDecorators(null, raw))

            const tagPMs =  {...pm.children.tagPMs, ...newTagPMs }


/*
            export const createCatPM = (model:CatModel, children:{[tag:string]:any}, tags:any, raw:string, state:any, tagPMs:{[tag:string]:TagPM} ):CatsPM => {


              if (1+2 == 17) {
                  throw new Error("TOOD - implement creating the cat pmodel")
              } 
              const newPm ={ }   // createCatPM(rurl, id, schema1, children, pm.tags, raw, state, tagPMs) 
                  
                

            // -- sets the created CatsPM onto the pageAsset
            yield $$.SET_CAT(newPm)
          }*/
        Ok(null)
      }}).catch(e => {
        return Err(e)
      }) 
      
      )
     // <-- not s
  }) 
  })



  
  