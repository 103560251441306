const NAME:RegExp = /^[a-zA-Z]+$/
export const validUsername = v => NAME.test(v) ? null : "Invalid username"
export const validEmail = v => (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)) ? null : "Invalid email"
export const validPassword = len =>  v => (!v || v.length < len) ? "Password must be at least 6 characters" : null
export const validFirebasePassword = validPassword(6)

export const validPasswordConfirm = (confirm, {password}) => {
    if (!confirm) return "Enter password confirmation"
    if (confirm != password)  return 'Password does not match confirmation field';  
    return null
  }